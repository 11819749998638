import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";

interface IExportParkingTicketDialogProps {
	shouldShow: boolean,
	handleClose: () => void,
	parkingLotIdentifier: string
	parkingLotName: string
}

const ExportParkingTicketDialog = ({ shouldShow, handleClose, parkingLotIdentifier, parkingLotName }: IExportParkingTicketDialogProps) => {

	const [fromValue, setFromValue] = useState(dayjs().subtract(30, "days"));
	const [toValue, setToValue] = useState(dayjs());

	const onSave = async () => {
		const startTimestamp = fromValue.startOf("day").toDate().getTime(); // start timetamp as unix milli
		const endTimestamp = toValue.endOf("day").toDate().getTime(); // end timetamp as unix milli
		const response = await fetch(`${process.env.REACT_APP_API_URL}/parkingTicket/export/${parkingLotIdentifier}?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`, {
			headers: {
				API_KEY: process.env.REACT_APP_API_KEY!,
			}
		});

		if (response.status === 200) {
			toast.success("Report erfolgreich erstellt.");
			// success
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "Export.xlsx";
			a.click();
		} else {
			// error
			toast.error("Ein Fehler ist aufgetreten beim erstellen des Reports.");
		}
	};

	return (
		<Dialog open={shouldShow}>
			<DialogTitle>Export Excel</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack mb={2}>
						<Typography>Aktivität des Parkplatz {parkingLotName} wird in eine Excel Datei exportiert.</Typography>
						<Typography>Bitte wählen sie den gewünschten Zeitraum.</Typography>
					</Stack>
				</DialogContentText>
				<Stack
					direction="row"
					columnGap={3}
				>
					<DesktopDatePicker
						label="Von"
						format="DD. MMMM"
						value={fromValue}
						disableFuture

						onChange={(e) => setFromValue(e as any)}
					/>

					<DesktopDatePicker
						label="Bis"
						value={toValue}
						format="DD. MMMM"
						disableFuture
						onChange={(e) => setToValue(e as any)}
					/>
				</Stack>
				<DialogContentText mt={2}>
					<Stack mb={2} rowGap={1}>
						<Typography>Der Excel Export beinhaltet jegliche die Parkplatzaktivität vom {fromValue?.format("DD. MMMM")} 0 Uhr bis {toValue?.format("DD. MMMM")} 23:59 Uhr.</Typography>
						<Typography variant="caption">Hinweis: Es sind <b> nur Fahrzeuge enthalten, die bereits ausgefahren</b> sind. Um Fahrzeuge zu sehen die gerade am Parkplatz sind, nutzen Sie die vorliegende online Ansicht. </Typography>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Abbrechen
				</Button>
				<div style={{ flex: "1 0 0" }} />
				<Button onClick={onSave}>
					Download
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ExportParkingTicketDialog;