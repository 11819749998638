import { Info } from "@mui/icons-material";
import { Tooltip, Box } from "@mui/material";

interface Props {
	tooltip: string,
	children: JSX.Element | string,
	sx?: object
	positionIconUsingAbsolute?: boolean
}

const IndicatedTooltip = ({ tooltip, sx, children, positionIconUsingAbsolute = true }: Props) => {
	return (
		<Tooltip
			title={tooltip}
			sx={sx}
		>
			<Box sx={{ position: "relative" }}>
				{children}
				<Info color="info" fontSize="inherit" sx={{ position: positionIconUsingAbsolute ? "absolute" : "block", top: -2, right: -12 }} />
			</Box>
		</Tooltip>);
};

export default IndicatedTooltip;