import { Box, Typography } from "@mui/material";
import React from "react";
import { PropTypes } from "prop-types";

const TitleLine = ({ title, children }) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} marginX={2}>
			<Typography variant="h5">{title}</Typography>
			<Box sx={{ display: "flex", flexDirection: "row", columnGap: "15px" }}>
				{children}
			</Box>
		</Box>
	);
};

TitleLine.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any
};

export default TitleLine;