import { useState } from "react";
import { Button, TextField, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useParkingLotOwnerData, useSaveParkingLotOwner } from "../../hooks/useParkingLotOwnerData";
import { ParkingLotOwner } from "../../types/ParkingLotOwner";
import { setUsingKey } from "../../utils/utils";

const BasicCommunityData = () => {

	const { parkingLotOwnerIdentifier } = useParams();
	const { isLoading, isError } = useParkingLotOwnerData(parkingLotOwnerIdentifier as string, {
		onSuccess: (data: ParkingLotOwner) => setLocalParkingLotOwner(data)
	});
	const { mutate: saveParkingLotOwner } = useSaveParkingLotOwner();
	const [localParkingLotOwner, setLocalParkingLotOwner] = useState<ParkingLotOwner | null>(null);


	const handleParkingLotOwnerFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (localParkingLotOwner !== null) {
			setLocalParkingLotOwner(setUsingKey({ ...localParkingLotOwner }, event.target.name, event.target.value));
		} else {
			console.error("localParkingLotOwner is null in handleParkingLotOwnerFieldChange");
		}
	};

	if (isError) {
		return <Typography variant="body1">Ein Fehler ist aufgetreten beim Laden der Informationen </Typography>;
	}

	if (isLoading || localParkingLotOwner === null) {
		return <Typography variant="body1">Lade Parkplatzbesitzer...</Typography>;
	}

	return (
		<Stack sx={{
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			maxWidth: "50%",
			rowGap: "20px",
			margin: "10vh auto"
		}}>
			<TextField fullWidth data-testid="tf-basic-community-communityname" label="Gemeindename" value={localParkingLotOwner?.name} name="name" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-street" label="Straße" value={localParkingLotOwner?.address.street} name="address.street" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-zip" label="Postleitzahl" value={localParkingLotOwner?.address.zipCode} name="address.zipCode" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-city" label="Stadt" value={localParkingLotOwner?.address.city} name="address.city" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-atu" label="ATU" value={localParkingLotOwner?.atu} name="atu" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-qrurl" label="QR Redirect URL" value={localParkingLotOwner?.qrRedirectUrl} name="qrRedirectUrl" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-telNr" label="Telefonnummer" value={localParkingLotOwner?.telNr} name="telNr" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-contact" label="Kontaktinformationen" value={localParkingLotOwner?.contactInfo} name="contactInfo" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<TextField fullWidth data-testid="tf-basic-community-comment" label="Kommentar" value={localParkingLotOwner?.comment} name="comment" onChange={handleParkingLotOwnerFieldChange}></TextField>
			<Button
				sx={{
					display: "block",
					margin: "5vh auto",
					width: "200px",
					flexWrap: "wrap"
				}}
				onClick={() => saveParkingLotOwner(localParkingLotOwner!)}
				variant="contained"
				style={{ backgroundColor: "#00451d", color: "#fff" }}
			>
				Speichern
			</Button>
		</Stack>
	);
};

export default BasicCommunityData;