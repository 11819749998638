import { LicencePlateParkingLot, ParkingLot } from "../types/ParkingLotOwner";

export const isToday = (date: Date): boolean => {
	const today = new Date();
	return date.getDate() == today.getDate() &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear();
};

export const setUsingKey = (object: any, path: string, value: any): any => {
	let schema = object;
	const pList = path.split(".");
	const len = pList.length;
	for (let i = 0; i < len - 1; i++) {
		const elem = pList[i];
		if (!schema[elem]) schema[elem] = {};
		schema = schema[elem];
	}

	schema[pList[len - 1]] = value;
	return object;
};

export const isLicencePlateParkingLot = (parkingLotType: string | undefined): boolean => {
	return parkingLotType === "LicencePlateParkingLot";
};

export const transformLicencePlateParkingLotToParkingLot = (licencePlateParkingLot: LicencePlateParkingLot): ParkingLot => {
	const parkingLot = { ...licencePlateParkingLot } as any;
	parkingLot.type = "ParkingLot";
	parkingLot.cars = undefined;
	parkingLot.paymentPlans = undefined;
	return parkingLot as ParkingLot;
};

export const transformParkingLotToLicencePlateParkingLot = (parkingLot: ParkingLot): LicencePlateParkingLot => {
	const licencePlateParkingLot = { ...parkingLot } as any;
	licencePlateParkingLot.type = "LicencePlateParkingLot";
	licencePlateParkingLot.cars = [];
	licencePlateParkingLot.paymentPlans = [];
	return licencePlateParkingLot as LicencePlateParkingLot;
};