import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ReactNode } from "react";

interface Props {
	shouldShow: boolean,
	title: string,
	children: ReactNode,
	onCancel: () => void,
	onConfirm: () => void
}

const DeleteConfirmationDialog = ({ shouldShow, onConfirm, onCancel, title, children }: Props) => {

	return (
		<Dialog open={shouldShow}>
			<DialogTitle>
				{title}
			</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<Button
					style={{ marginLeft: "10px" }}
					onClick={onCancel}
				>
					Abbrechen
				</Button>
				<Box style={{ flex: "1 0 0" }} />
				<Button
					style={{ marginRight: "10px" }}
					color="error"
					onClick={onConfirm}
				>
					Löschen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteConfirmationDialog;