import { Box } from "@mui/material";
import { CarInParkingLot, CarInParkingLotPaymentStatus, ParkingLotPaymentPlan } from "../../types/ParkingLotOwner";

import { DataGrid, GridColDef, GridComparatorFn, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

const formatEntryTime = (params: GridValueFormatterParams<{ planned: boolean | null, date: Date | null }>) => {
	if (params.value.date === null) {
		return "-";
	}
	return `${params.value.planned ? "geplant: " : ""}${params.value.date.toLocaleDateString("de-DE", { month: "long", day: "numeric" })}, ${params.value.date.toLocaleTimeString("de-DE")}`;
};

const entryTimeSortComparator: GridComparatorFn<{ planned: boolean | null, date: Date | null }> = (v1, v2) => {
	if (v1.date === null && v2.date !== null) {
		return 1;
	}
	if (v1.date !== null && v2.date === null) {
		return -1;
	}
	if (v1.date === null && v2.date === null) {
		return 0;
	}
	return dayjs(v1.date).unix() - dayjs(v2.date).unix();
};

const formatDateRowValue = (params: GridValueFormatterParams<string>): string => {
	if (params.value === null) {
		return "-";
	}
	const date = new Date(params.value);
	return date.toLocaleDateString("de-DE", { month: "long", day: "numeric" }) + ", " + date.toLocaleTimeString("de-DE");
};

const formatPaymentPlan = (params: GridValueFormatterParams<ParkingLotPaymentPlan>): string => {
	if (params.value === null) {
		return "-";
	} else {
		return params.value.name;
	}
};

const formatPaymentMethod = (params: GridValueFormatterParams<string>): string => {
	if (params.value === null) {
		return "-";
	} else {
		return params.value;
	}
};

const formatPaymentStatus = (params: GridValueFormatterParams<string>): string => {
	switch (params.value as CarInParkingLotPaymentStatus) {
		case "NOT_PAID":
			return "nicht bezahlt";
		case "PAID":
			return "bezahlt";
		case "PENDING":
			return "ausstehend";
		case "PAYMENT_INTENT_REQUESTED":
			return "checkout besucht";
		case "MANUAL_SMS_SENT":
			return "SMS versendet";
	}
};

const getEntryTime = (params: GridValueGetterParams) => {
	if (params.row.arrivalDateTime) {
		return {
			planned: false,
			date: new Date(params.row.arrivalDateTime)
		};
	} else if (params.row.plannedArrivalDateTime) {
		return {
			planned: true,
			date: new Date(params.row.plannedArrivalDateTime)
		};
	} else {
		return {
			planned: null,
			date: null
		};
	}
};

const getIsOverdue = (params: GridValueGetterParams) => {
	if (params.row.paymentPlanEndTime === null) {
		return "-";
	} else if (dayjs(params.row.paymentPlanEndTime) < dayjs()) {
		return "Ja";
	} else {
		return "Nein";
	}
};

const getDidPay = (params: GridValueGetterParams) => {
	if (params.row.paymentStatus === "PAID") {
		return "Ja";
	} else {
		return "Nein";
	}
};

const columns: GridColDef[] = [
	{ field: "licencePlate", headerName: "Kennzeichen", flex: 1 },
	{ field: "arrivalDateTime", headerName: "Einfahrt", flex: 1, valueGetter: getEntryTime, valueFormatter: formatEntryTime, sortComparator: entryTimeSortComparator },
	{ field: "selectedPaymentPlan", headerName: "Tarif", flex: 1, valueFormatter: formatPaymentPlan },
	{ field: "didPay", headerName: "bezahlt", flex: 1, valueGetter: getDidPay },
	{ field: "paymentStatus", headerName: "Bezahlungsstatus", flex: 1, valueFormatter: formatPaymentStatus },
	{ field: "paymentMethod", headerName: "Bezahlungsmethode", flex: 1, valueFormatter: formatPaymentMethod },
	{ field: "paymentPlanEndTime", headerName: "letzte erlaubte Ausfahrt", description: "Uhrzeit, zu der Das Auto spätestens ausfahren muss.", flex: 1, valueFormatter: formatDateRowValue },
	{
		field: "isOverdue",
		headerName: "ist zu lange geparkt",
		flex: 1,
		valueGetter: getIsOverdue
	}
];

export interface ICarDataGridProps {
	cars: Array<CarInParkingLot>
}


const CarDataGrid = ({ cars }: ICarDataGridProps) => {


	return (
		<Box sx={{ height: "800px", width: "100%" }}>
			<DataGrid
				rows={cars}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 15,
						},
					},
					sorting: {
						sortModel: [{ field: "arrivalDateTime", sort: "desc" }]
					}
				}}
				pageSizeOptions={[5, 10, 15]}
				disableRowSelectionOnClick
			/>
		</Box>
	);
};

export default CarDataGrid;