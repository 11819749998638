import { FormControl, InputLabel, ListItemText, OutlinedInput, Select, Stack, TextField, MenuItem, Checkbox, SelectChangeEvent } from "@mui/material";
import { ParkingLotTagsArray } from "../../../types/ParkingLotOwner";
import { ParkingLotForm } from "../ParkingLotForm";
import { validateParkingLotForm } from "../helpers/ValidateParkingLotForm";

export interface IGeneralTabProps {
	formValues: ParkingLotForm,
	setFormValues: (v: any) => void,
	handleInputChange: (e: any) => void,
	formErrors: any,
	setErrors: (e: any) => void
}

const GeneralTab = ({ formValues, setFormValues, handleInputChange, formErrors, setErrors }: IGeneralTabProps) => {

	const handleBlur = (e: any) => {
		const errors = validateParkingLotForm(formValues, e.target.name);
		setErrors(errors);
	};

	const mapToDisplayName = (label: string): string => {
		if (label === "PARK_AND_RIDE") {
			return "Park and Ride";
		}
		if (label === "EVENT") {
			return "Veranstaltung";
		}
		return label;
	};

	const handleMultipleSelectChange = (event: SelectChangeEvent<typeof ParkingLotTagsArray>) => {
		const { target: { value } } = event;

		const parkingLotCopy = { ...formValues.parkingLot };
		parkingLotCopy.tags = value as string[];
		setFormValues({
			...formValues,
			parkingLot: parkingLotCopy
		}
		);
	};

	return (
		<Stack width="100%">
			<TextField
				margin="dense"
				label="Name"
				name="parkingLot.name"
				error={formErrors?.parkingLot?.name !== undefined}
				helperText={formErrors?.parkingLot?.name}
				value={formValues.parkingLot?.name}
				onChange={handleInputChange}
				onBlur={handleBlur}
			/>
			<Stack
				direction="row"
				columnGap={2}
			>
				<TextField
					margin="dense"
					label="Straße"
					name="parkingLot.address.street"
					error={formErrors?.parkingLot?.address?.street !== undefined}
					helperText={formErrors.parkingLot?.address?.street}
					sx={{ width: "80%" }}
					value={formValues.parkingLot?.address?.street}
					onChange={handleInputChange}
					onBlur={handleBlur}
				/>
				<TextField
					margin="dense"
					label="PLZ"
					sx={{ width: "20%" }}
					name="parkingLot.address.zipCode"
					value={formValues.parkingLot?.address?.zipCode ?? ""}
					onChange={handleInputChange}
					error={formErrors?.parkingLot?.address?.zipCode !== undefined}
					helperText={formErrors?.parkingLot?.address?.zipCode}
					onBlur={handleBlur}
				/>
			</Stack>
			<TextField
				margin="dense"
				label="Stadt"
				name="parkingLot.address.city"
				value={formValues.parkingLot?.address?.city}
				onChange={handleInputChange}
				error={formErrors?.parkingLot?.address?.city !== undefined}
				helperText={formErrors?.parkingLot?.address?.city}
				onBlur={handleBlur}
			/>
			<TextField
				margin="dense"
				name="parkingLot.extras"
				label="Extras"
				fullWidth
				placeholder="Um mehrere Extras hinzuzufügen, einfach mit Beistrich trennen"
				value={formValues.parkingLot?.extras}
				onChange={handleInputChange}
			/>
			<TextField
				margin="dense"
				name="parkingLot.link.qrCodeDestinationURL"
				label="QR Link URL"
				fullWidth
				placeholder="Um mehrere Extras hinzuzufügen, einfach mit Beistrich trennen"
				value={formValues.parkingLot?.link?.qrCodeDestinationURL}
				onChange={handleInputChange}
			/>
			<FormControl margin="dense">
				<InputLabel>Tag</InputLabel>
				<Select
					multiple
					value={formValues.parkingLot?.tags as Array<string> ?? []}
					onChange={handleMultipleSelectChange}
					input={<OutlinedInput label="Tag" />}
					renderValue={(selected) => selected.map(mapToDisplayName).join(", ")}
					fullWidth
				>
					{ParkingLotTagsArray.map((tag) => (
						<MenuItem key={tag} value={tag} sx={{ maxHeight: "40px" }}>
							<Checkbox checked={formValues.parkingLot?.tags?.indexOf(tag) > -1} />
							<ListItemText primary={mapToDisplayName(tag)} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Stack>
	);
};

export default GeneralTab;