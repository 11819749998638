import React, { useEffect, useState } from "react";
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Checkbox, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Box, IconButton, Stack } from "@mui/material";
import TitleLine from "../../components/UI/TitleLine";
import ActionButton from "../../components/UI/ActionButton";
import ProviderMappingDialog from "./ProviderMappingDialog";
import { useDeleteProviderMapping, useProviderMappings, useSaveProviderMapping } from "../../hooks/useProviderMappings";
import { Delete, Edit } from "@mui/icons-material";
import { ProviderMapping } from "../../types/ProviderMapping";
import { useParams } from "react-router-dom";
import DeleteConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { useParkingLotsOfOwner } from "../../hooks/useParkingLotData";

const ProviderMappingPage = () => {

	const { parkingLotOwnerIdentifier } = useParams();
	const { data: providerMappings, isLoading, error } = useProviderMappings();
	const [showAddDialog, setShowAddDialog] = useState(false);
	const [deleteMapping, setDeleteMapping] = useState<ProviderMapping | null>(null);
	const { mutate: deleteProviderMapping } = useDeleteProviderMapping();
	const { mutate: saveProviderMapping } = useSaveProviderMapping(parkingLotOwnerIdentifier!);
	const { data: parkingLots, isSuccess: parkingLotsLoadedSuccessfully } = useParkingLotsOfOwner(parkingLotOwnerIdentifier!);

	const renderParkingLotName = (mapping: ProviderMapping) => {
		if (!parkingLotsLoadedSuccessfully || mapping.parkingLotIdentifier === null || mapping.parkingLotIdentifier === "" || mapping.parkingLotIdentifier === undefined) {
			return "<keine Anbindung>";
		}
		return parkingLots.find(lot => lot.identifier === mapping.parkingLotIdentifier)?.name ?? mapping.parkingLotIdentifier;
	};

	if (isLoading) {
		return <Box />;
	}

	if (error) {
		return <Box>An error has occurred while loading the data.</Box>;
	}

	return (
		<Box>
			<TitleLine title="Anbindungen">
				<ActionButton title="Hinzufügen" bgColor="#00451d" onClick={() => setShowAddDialog(true)} />
			</TitleLine>

			<TableContainer component={Paper}>
				<Table size="small" aria-label="Anbindung Table">
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: "bold" }} align="left">Anbieter</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="left">Anbieter interner Parkplatzname</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="left">Mobil365 Parkplatzname</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="left">Aktionen</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							providerMappings!.map((mapping, idx) => (
								<TableRow key={idx}>
									{/*<TableCell align="left"><Checkbox color="primary" checked={false} onClick={() => handleCheckboxClick(idx)} /></TableCell>*/}
									<TableCell align="left" component="th" scope="row"> {mapping.provider} </TableCell>
									<TableCell align="left">{mapping.providerParkingLotName}</TableCell>
									<TableCell align="left">{renderParkingLotName(mapping)}</TableCell>
									<TableCell align="left">
										{/* <IconButton>
											<Edit />
										</IconButton> */}
										<IconButton onClick={() => setDeleteMapping(mapping)} color="error">
											<Delete />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
			</TableContainer>

			<ProviderMappingDialog
				open={showAddDialog}
				onClose={() => setShowAddDialog(false)}
				onSave={saveProviderMapping}
			/>

			<DeleteConfirmationDialog
				shouldShow={deleteMapping != null}
				title="Parkplatz - Anbieter Verknüpfung löschen"
				onCancel={() => setDeleteMapping(null)}
				onConfirm={() => { deleteProviderMapping(deleteMapping!); setDeleteMapping(null); }}

			>
				<p style={{ margin: "0 0 15px 0" }}>
					Sind Sie sicher, dass Sie diese Parkplatz Verknüpfung löschen möchten? <br />
					Dieser Vorgang ist nicht wiederherstellbar!
				</p>
			</DeleteConfirmationDialog>
		</Box >
	);
};

export default ProviderMappingPage;