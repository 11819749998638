import { ParkingLotOwner, Gate, ParkingLot, LicencePlateParkingLot, ParkingSpotTypeDetails } from "../types/ParkingLotOwner";
import { Patch } from "../types/Patch";
import { ProviderMapping } from "../types/ProviderMapping";
import { ParkingSpaceStatus } from "../types/Status";
import { isLicencePlateParkingLot } from "../utils/utils";
import ProviderMappingService from "./ProviderMappingService";

class ParkingLotService {

	async updateStatus(communityName: string, parkingLotName: string, status: ParkingSpaceStatus) {
		if (communityName && parkingLotName) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/parkingLots/status?communityName=${communityName}&parkingLotName=${parkingLotName}`, {
				headers: {
					"Content-Type": "application/json",
					"API_KEY": process.env.REACT_APP_API_KEY!,
				},
				method: "POST",
				body: JSON.stringify(status)
			});
			return response.status;
		} else {
			throw new Error("Did not send because Dialog Store is incomplete.");
		}
	}

	async getPatches(communityName: string): Promise<Array<Patch>> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/community/${communityName}/patch`, {
			headers: {
				API_KEY: process.env.REACT_APP_API_KEY!
			}
		});
		return result.json();
	}

	async getPatchByParkingLotIdentifier(parkingLotIdentifier: string): Promise<Patch> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/patch?parkingLotIdentifier=${parkingLotIdentifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			}
		});
		return result.json();
	}

	async savePatch(patch: Patch): Promise<Patch> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/patch`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			},
			method: "POST",
			body: JSON.stringify(patch)
		});
		return result.json();
	}

	async deletePatch(parkingLotIdentifier: string): Promise<void> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/patch/${parkingLotIdentifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			},
			method: "DELETE"
		});
	}

	async handleParkingLotFormSave(communityName: string, parkingLot: ParkingLot, patch?: Patch, providerMapping?: ProviderMapping) {
		// TODO - send all requests necessary to save
		if (patch) {
			this.savePatch(patch);
		} else {
			this.deletePatch(parkingLot.identifier);
		}
		if (providerMapping) {
			ProviderMappingService.saveProviderMapping(providerMapping);
		}
		this.updateParkingLot(communityName, parkingLot);
	}

	private async updateParkingLot(communityName: string, parkingLot: ParkingLot): Promise<ParkingLot> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLot?communityName=${communityName}&parkingLotIdentifier=${parkingLot.identifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			},
			method: "POST",
			body: JSON.stringify(parkingLot)
		});
		return result.json();
	}

	async loadParkingLotsForOwner(ownerIdentifier: string): Promise<Array<ParkingLot>> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLot?ownerIdentifier=${ownerIdentifier}&excludeCars=true`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			}
		});
		return result.json();
	}

	async loadParkingLotDetails(parkingLotIdentifier: string): Promise<ParkingLot> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLot/${parkingLotIdentifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			}
		});
		return result.json();
	}

	async saveParkingLot({ ownerIdentifier, parkingLot }: { ownerIdentifier: string, parkingLot: ParkingLot | LicencePlateParkingLot }): Promise<ParkingLot | LicencePlateParkingLot> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/${isLicencePlateParkingLot(parkingLot.type) ? "lp/" : ""}parkingLot/${ownerIdentifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!,
			},
			method: "PUT",
			body: JSON.stringify(parkingLot)
		});
		return result.json();
	}


	async handleChangeGateStatus({ parkingLot }: { parkingLot: ParkingLot }): Promise<ParkingLotOwner> {
		// display loading (in components!)
		const response = await fetch(process.env.REACT_APP_API_URL + "/gateControl", {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			},
			method: "POST",
			body: JSON.stringify({
				parkingLotIdentifier: parkingLot.identifier,
				command: parkingLot.gate!.gateStatus === "OPEN" ? "TURN_LIGHT_ON" : "TURN_LIGHT_OFF"
			})
		});
		// stop display loading (in components!)
		return response.json();
	}

	async deleteParkingLot({ parkingLotIdentifier }: { parkingLotIdentifier: string }): Promise<void> {
		// display loading (in components!) 
		await fetch(`${process.env.REACT_APP_API_URL}/parkingLot/${parkingLotIdentifier}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			},
			method: "DELETE",
		});
		// stop display loading (in components!)
	}

	getTotalOccupiedSpacesForParkingLot(parkingLot: ParkingLot | LicencePlateParkingLot) {
		return Object.entries(parkingLot.parkingSpots)
			.map(([, value]) => (value as ParkingSpotTypeDetails).currentlyOccupied)
			.reduce((prev, curr) => prev += curr, 0);
	}

	getTotalMaximumSpotsForParkingLot(parkingLot: ParkingLot | LicencePlateParkingLot) {
		return Object.entries(parkingLot.parkingSpots)
			.map(([, value]) => (value as ParkingSpotTypeDetails).maximumSpots)
			.reduce((prev, curr) => prev += curr, 0);
	}
}

export default new ParkingLotService();