import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import NavbarNoRoutes from "./components/Navbar/NavbarNoRoutes";
import BasicCommunityData from "./pages/BasicCommunityData/BasicCommunityData";
import ParkingLotList from "./pages/ParkingLot/ParkingLotList";
import Login from "./pages/Login/Login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { useEffect } from "react";
import ProviderMappingPage from "./pages/ProviderMapping/ProviderMapping";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import MonitoringPage from "./pages/Monitoring/MonitoringPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import UserContext from "./context/UserDetailsContext";
import { Box } from "@mui/material";
import ParkingLotDetails from "./pages/ParkingLot/ParkingLotDetails";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();
function App() {

	useEffect(() => {
		document.title = "Mobil365 Admin";
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<QueryClientProvider client={queryClient}>
					<ToastContainer position="bottom-center" />
					<Router>
						<UserContext>
							<Box>
								<Routes>
									<Route path="/login" element={<NavbarNoRoutes />} />
									<Route path="/" element={<NavbarNoRoutes />} />
									<Route path="*" element={<Navbar />} />
								</Routes>
								<Routes>
									<Route element={<ProtectedRoutes />}>
										<Route path="/gemeinden/:parkingLotOwnerIdentifier/basic-data" element={<BasicCommunityData />} />
										<Route path="/gemeinden/:parkingLotOwnerIdentifier/parking-lots" element={<ParkingLotList />} />
										<Route path="/gemeinden/:parkingLotOwnerIdentifier/parking-lots/:parkingLotIdentifier" element={<ParkingLotDetails />} />
										<Route path="/gemeinden/:parkingLotOwnerIdentifier/monitoring" element={<MonitoringPage />} />
										<Route path="/gemeinden/:parkingLotOwnerIdentifier/anbindung" element={<ProviderMappingPage />} />
										<Route path="/" element={<Homepage />} />
									</Route>
									<Route path="/login" element={<Login />} />
								</Routes>
							</Box>
						</UserContext>
					</Router>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</LocalizationProvider>
		</ThemeProvider >
	);
}

export default App;
