import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserDetails } from "../context/UserDetailsContext";

const ProtectedRoutes = () => {

	const { userDetails } = useUserDetails();

	if (userDetails.loginState === "LOGGED_IN")
		return (<Outlet />);
	else {
		return <Navigate to="/login" />;
	}
};

export default ProtectedRoutes;