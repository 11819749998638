import { AddCircle, PlusOne } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useParkingLotOwnerData } from "../../hooks/useParkingLotOwnerData";
import ProviderMappingService from "../../service/ProviderMappingService";
import { ParkingLotOwner, ParkingLot } from "../../types/ParkingLotOwner";
import type { ProviderMapping } from "../../types/ProviderMapping";

interface IProviderMappingDialogProps {
	open: boolean,
	onClose: () => void,
	onSave: (mapping: ProviderMapping) => void
}

interface DialogData {
	providerName: string,
	providerParkingLotName: string,
	internalParkingLotName: string, // or camera mac address
	extrasCommunityName: string
}

interface HikvisionLineCrossing {
	regionID: string,
	actionWhenCrossed: "ENTER" | "EXIT"
}


/*
TODOS:
	- Validate Form Fields before sendin them to the Server
	- Add Success/Error Snackbars
	- Refactor:
		- Extras should be in separate component
		- Extras should allow for easy modification (new interfaces etc might also need it)
		- the plus line should be reusable (also for parking spots later)
*/

const ProviderMappingDialog = ({ open, onClose, onSave }: IProviderMappingDialogProps) => {

	const [lineCrossings, setLineCrossings] = useState<Array<HikvisionLineCrossing>>([{ regionID: "1", actionWhenCrossed: "ENTER" }]);
	const { parkingLotOwnerIdentifier } = useParams();
	const queryClient = useQueryClient();

	const [data, setData] = useState<DialogData>({
		providerName: "",
		providerParkingLotName: "",
		internalParkingLotName: "",
		extrasCommunityName: parkingLotOwnerIdentifier ?? ""
	});

	const onFormInput = (key: keyof DialogData, value: string) => {
		const temp = { ...data };
		temp[key] = value;
		setData(temp);
	};

	const handleLineCrossingActionChange = (lineCrossingIdx: number, action: string) => {
		const temp = [...lineCrossings];
		temp[lineCrossingIdx].actionWhenCrossed = action as "ENTER" | "EXIT";
		setLineCrossings(temp);
	};

	const handleLineCrossingRegionChange = (lineCrossingIdx: number, regionID: string) => {
		const temp = [...lineCrossings];
		temp[lineCrossingIdx].regionID = regionID;
		setLineCrossings(temp);
	};

	const onHikvisionPlusClick = () => {
		const temp = [...lineCrossings];
		temp.push({ regionID: "", actionWhenCrossed: "ENTER" });
		setLineCrossings(temp);
	};

	const hasExtras = (providerName: string) => {
		return providerName === "Hikvision";
	};

	const getParkingLotIdentifierForName = (parkingLotName: string): string | undefined => {
		const community = queryClient.getQueriesData(["parkingLotOwner", parkingLotOwnerIdentifier])[0][1] as ParkingLotOwner;
		return ProviderMappingService.getIdentifierForParkingLot(community, parkingLotName);
	};

	const handleSave = () => {


		let parkingLotIdentifier = getParkingLotIdentifierForName(data.internalParkingLotName);
		if (parkingLotIdentifier === undefined) {
			// throw new Error("Could not find a parkinglot with the given name.");
			parkingLotIdentifier = ""; // adding a empty provider mapping is allowed (and makes sense), because it will show up in the add/edit parking lot dialog
		}

		const providerMapping: ProviderMapping = {
			provider: data.providerName,
			providerParkingLotName: data.providerParkingLotName,
			parkingLotIdentifier: parkingLotIdentifier,
			extras: null
		};
		if (data.providerName.toUpperCase() === "HIKVISION") {
			providerMapping.extras = {
				communityName: data.extrasCommunityName,
				oneWayDetections: lineCrossings.filter(c => c.regionID !== "")
			};
		}

		onSave(providerMapping);
		onClose();
	};

	return (
		<Dialog open={open}>
			<DialogTitle>
				Parkplatz - Anbieter Verknüpfung anlegen
			</DialogTitle>
			<DialogContent>
				<Stack
					direction="column"
					rowGap={2}
				>
					<Typography variant="body1">
						Bitte achte darauf, dass ein Mobil365-Parkplatz nur von einem Anbieter Daten bekommt. <br />
						Falls dies nicht der Fall ist können Bugs entstehen!
					</Typography>
					<FormControl fullWidth>
						<InputLabel >Anbieter</InputLabel>
						<Select
							label="Anbieter"
							value={data.providerName}
							onChange={(e) => { onFormInput("providerName", e.target.value); }}
						>
							<MenuItem value={"S&B"}>Scheidt und Bachmann</MenuItem>
							<MenuItem value={"Dahuia"}>Dahua</MenuItem>
							<MenuItem value={"Hikvision"}>Hikvision</MenuItem>
							<MenuItem value={"Axis"}>Axis</MenuItem>
						</Select>
					</FormControl>
					<TextField
						fullWidth
						placeholder="Gruenberg"
						label="Anbieter Parkplatz-Name"
						value={data.providerParkingLotName}
						onChange={(e) => onFormInput("providerParkingLotName", e.target.value)} />
					<TextField
						fullWidth
						error={false}
						placeholder="Gruenberg Seilbahn"
						label="Mobil365 Parkplatz-Name"
						value={data.internalParkingLotName}
						onChange={(e) => onFormInput("internalParkingLotName", e.target.value)} />

					{/* Extras */}
					{
						hasExtras(data.providerName) &&
						<Stack direction="column" justifyContent="center" rowGap={2}>
							<Typography variant="overline">Extras</Typography>
							<TextField
								fullWidth
								placeholder="Gmunden"
								label="Gemeindename"
								value={data.extrasCommunityName}
								onChange={(e) => onFormInput("extrasCommunityName", e.target.value)}
							/>
							<Stack direction="column" justifyContent="spaceBetween" rowGap={2} width="100%">
								{
									lineCrossings.map((lineCrossing, idx) => (
										<Stack key={idx} direction="row" justifyContent="spaceBetween" columnGap={2} width="100%">
											<TextField
												placeholder={String(idx + 1)}
												label="Liniennummer"
												value={lineCrossings[idx].regionID}
												onChange={(e) => handleLineCrossingRegionChange(idx, e.target.value)}
											/>
											<FormControl fullWidth>
												<InputLabel >Aktion</InputLabel>
												<Select
													label="Aktion"
													value={lineCrossing.actionWhenCrossed}
													onChange={(e) => { handleLineCrossingActionChange(idx, e.target.value!); }}
												>
													<MenuItem value={"ENTER"}>Einfahrt</MenuItem>
													<MenuItem value={"EXIT"}>Ausfahrt</MenuItem>
												</Select>
											</FormControl>
										</Stack>
									))
								}
							</Stack>
							<Stack direction="row" alignItems="center" columnGap={2} width="100%">
								<Divider sx={{ flex: 1, marginBottom: 2 }} flexItem />
								<IconButton onClick={onHikvisionPlusClick}>
									<AddCircle color="primary" />
								</IconButton>
								<Divider sx={{ flex: 1, marginBottom: 2 }} flexItem />
							</Stack>
						</Stack>
					}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack direction="row" justifyContent="space-between" mx={2} width="100%">
					<Button onClick={onClose} style={{ marginRight: "0" }} color="error">
						Abbrechen
					</Button>
					<Button onClick={handleSave} style={{ marginRight: "0" }}>
						Speichern
					</Button>
				</Stack>
			</DialogActions>

		</Dialog >
	);
};

export default ProviderMappingDialog;
