import { Link } from "react-router-dom";
import { ParkingLotOwner } from "../../types/ParkingLotOwner";
import { useParkingLotOwners } from "../../hooks/useParkingLotOwnerData";
import { List, ListItem, Stack, Typography } from "@mui/material";


const Homepage = () => {

	const { data: communities, isLoading, isError } = useParkingLotOwners();

	const createCommunityLink = (community: ParkingLotOwner) => {
		return "/gemeinden/" + community.name + "/basic-data";
	};

	if (isLoading) {
		return <Typography variant="body1">Lade Gemeinden...</Typography>;
	}

	if (isError) {
		return <Typography variant="body1">Ein Fehler ist aufgetreten beim Laden der Gemeinden </Typography>;
	}

	return (
		<Stack direction="column" justifyContent="center" alignItems="center">
			<Typography variant="h4">Gemeinden</Typography>
			<List sx={{ listStyle: "none" }}>
				{
					communities.map((community: ParkingLotOwner) => {
						return <Link key={community.id} to={createCommunityLink(community)}><ListItem sx={{ textDecoration: "none", color: "blue" }}>{community.name}</ListItem></Link>;
					})}
			</List>
		</Stack>
	);
};

export default Homepage;