import { QueryClient } from "@tanstack/react-query";
import { ParkingLotOwner, ParkingLot } from "../types/ParkingLotOwner";

class ParkingLotOwnerService {


	async loadAllowedParkingLotOwners(ownerIdentifiers: Array<string> | undefined | null): Promise<Array<ParkingLotOwner>> {
		if (ownerIdentifiers === undefined || ownerIdentifiers === null) {
			return [];
		}
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLotOwner?ownerIdentifiers=${ownerIdentifiers.join(",")}`, {
			headers: {
				API_KEY: process.env.REACT_APP_API_KEY!
			}
		});
		return result.json();
	}

	async loadOwner(ownerIdentifier: string) {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLotOwner/${ownerIdentifier}`, {
			headers: {
				API_KEY: process.env.REACT_APP_API_KEY!
			}
		});
		return result.json();
	}

	async saveParkingLotOwner(owner: ParkingLotOwner): Promise<ParkingLotOwner> {
		const result = await fetch(`${process.env.REACT_APP_API_URL}/parkingLotOwner?cascadeParkingLots=false`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			},
			body: JSON.stringify(owner)
		});
		return result.json();
	}

}

export default new ParkingLotOwnerService();