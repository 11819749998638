import { Box } from "@mui/material";
import React from "react";

interface Props {
	backgroundColor: string
}

const StatusCircle = ({ backgroundColor }: Props) => {

	return (
		<Box sx={{ height: "20px", width: "20px", backgroundColor: backgroundColor, borderRadius: "50%", display: "inline-block" }} />
	);
};

export default StatusCircle;