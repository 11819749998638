import { Patch } from "./Patch";
import { ParkingSpaceStatus } from "./Status";

export type ParkingLotOwner = {
	id: string,
	name: string,
	atu: string,
	address: Address,
	identifier: string,
	parkingLots: Array<ParkingLot>,
	pnrs: Array<PNR>,
	qrRedirectUrl: string,
	telNr: string,
	contactInfo: string,
	comment: string
}

export type Address = {
	street: string,
	zipCode: number,
	city: string,
	location: {
		lat: number,
		lng: number
	},

}

export type ParkingLot = {
	type: string,
	identifier: string,
	name: string,
	address: Address,
	extras: Array<string>,
	gate: Gate | null,
	status: ParkingSpaceStatus,
	patch: Patch | null
	parkingSpots: any,
	tags: Array<string>
	link: { qrCodeDestinationURL: string } | null
}

export type LicencePlateParkingLot = {
	type: string,
	identifier: string,
	name: string,
	address: Address,
	extras: Array<string>,
	gate: Gate | null,
	status: ParkingSpaceStatus,
	parkingSpots: any,
	tags: Array<any> /* TODO */
	patch: Patch | null,
	paymentPlans: Array<ParkingLotPaymentPlan>,
	cars: Array<CarInParkingLot>,
	link: { qrCodeDestinationURL: string } | null
}

export type CarInParkingLot = {
	licencePlate: string,
	arrivalDateTime: Date,
	selectedPaymentPlan: ParkingLotPaymentPlan,
	paymentStatus: CarInParkingLotPaymentStatus
}

export type CarInParkingLotPaymentStatus = "PENDING" | "PAID" | "NOT_PAID" | "PAYMENT_INTENT_REQUESTED" | "MANUAL_SMS_SENT";
export type CarInParkingLotPaymentMethod = "CREDIT_CARD" | "SMS";

export type ParkingLotPaymentPlan = {
	name: string,
	allowedParkingMinutes: number,
	priceEuro: number
}

export type ParkingType = "STANDARD" | "WOMAN" | "DISABLED";
export const ParkingLotTagsArray = ["PARK_AND_RIDE", "EVENT"];
export type ParkingLotTag = typeof ParkingLotTagsArray[number];

export type Gate = {
	telNr: string,
	password: string,
	gateStatus: "OPEN" | "CLOSED"
}

export type ParkingSpotTypeDetails = {
	maximumSpots: number,
	currentlyOccupied: number
}

export type PNR = {
	name: string,
	address: Address,
	extras: Array<string>,
	gate: Gate | null,
	status: "ORANGE" | "GREEN" | "RED"
}