import React from "react";
import "./Navbar.style.css";
import { Link } from "react-router-dom";

const NavbarNoRoutes = () => {
	return (
		<nav className="navbarNoRoutes">
			<Link to="/" className="no-link">
				<img src="/logo-white.svg" alt="Logo" className="logo" />
				<span className="navbar-title">Administrationsportal</span>
			</Link>
		</nav>
	);
};

export default NavbarNoRoutes;