import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box } from "@mui/material";
import TitleLine from "../../components/UI/TitleLine";
import { useQuery } from "@tanstack/react-query";
import MonitoringService from "../../service/MonitoringService";
import StatusCircle from "../../components/UI/StatusCircle";
import IndicatedTooltip from "../../components/UI/IndicatedTooltip";


const MonitoringPage = () => {

	const { data, isLoading, isError } = useQuery(["monitoring"], MonitoringService.loadLatestUpdates, {
		refetchInterval: 30 * 1000 // every 30 seconds
	});

	if (isLoading) {
		return <Typography variant="body1">Loading...</Typography>;
	}

	if (isError) {
		return <Typography variant="body1">Fehler beim Laden der Daten.</Typography>;
	}

	return (
		<Box padding={0}>
			<TitleLine title={"Monitoring"} />
			{/* Todo: group monitor entries by type and then map over arrays to create this structure */}
			<TableContainer component={Paper}>
				{
					MonitoringService.groupMonitoringEntriesByType(data).map((monitoringSection, idx) => {
						return (
							<>
								<Table key={idx} size="small" aria-label="Monitoring Table">
									{monitoringSection.length > 0 &&
										<Typography variant="overline" pl={2}> {MonitoringService.getDisplayStringForType(monitoringSection[0].type)} </Typography>
									}
									{
										idx === 0 &&
										<TableHead>
											<TableRow>
												<TableCell style={{ fontWeight: "bold" }} align="left">
													<IndicatedTooltip
														tooltip="Bei Parkplätzen zeigt der Status hier lediglich an wann das Feld 'Freie Parkplätze' das letze mal geupdated wurde. Um den aktuellen Parkplatz-Status bezüglich Auslastung zu sehen, bitte die 'Parkplätze' Ansicht verwenden."
														positionIconUsingAbsolute={false}
													>
														Status
													</IndicatedTooltip>
												</TableCell>
												<TableCell style={{ fontWeight: "bold" }} align="left">Name</TableCell>
												<TableCell style={{ fontWeight: "bold" }} align="left">Letztes Update</TableCell>
												<TableCell style={{ fontWeight: "bold" }} align="left">Geupdated von</TableCell>
											</TableRow>
										</TableHead>
									}
									<TableBody>
										{
											monitoringSection.map((monitorEntry) => {
												return (
													<TableRow key={monitorEntry.identifier}>
														<TableCell sx={{ width: "10%" }} align="left"><StatusCircle backgroundColor={MonitoringService.calculateBackgroundColorForStatusCircle(monitorEntry)} /></TableCell>
														<TableCell sx={{ width: "20%" }} align="left">{monitorEntry.name}</TableCell>
														<TableCell sx={{ width: "20%" }} align="left">{MonitoringService.formatTimestamp(monitorEntry.latestUpdate)}</TableCell>
														<TableCell sx={{ width: "20%" }} align="left">{monitorEntry.updatedBy ?? "/"}</TableCell>
													</TableRow>);
											})
										}
									</TableBody>
								</Table>
							</>
						);
					})
				}
			</TableContainer>
		</Box>
	);
};
export default MonitoringPage;