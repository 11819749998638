import React, { useEffect, useState } from "react";
import { Button, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Login.style.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUserDetails } from "../../context/UserDetailsContext";
import UserService from "../../service/UserService";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Login = () => {

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();
	const { userDetails, setUserDetails } = useUserDetails();

	const showLoginErrorToast = (msg: string) => {
		toast(msg, { type: "error" });
		// stop display loading
	};

	const authenticate = async () => {
		// display loading
		console.log(process.env);
		await UserService.usernamePasswordLogin(username, password, {
			onSuccess: (userDetails) => {
				setUserDetails(userDetails);
				Cookies.set("usercookie", userDetails.user!.cookieUUID, { expires: 30 });
				Cookies.set("user-apikey", userDetails.user!.token, { expires: 30 });
				navigate("/");
				// stop display loading
			},
			onError: (msg: string) => {
				showLoginErrorToast(msg);
			}
		});
	};

	if (userDetails.loginState === "LOADING") {
		return (<div>loading</div>);
	}

	return (
		<div className="wrapper">
			<Typography variant="h3" fontSize="2.5rem" fontWeight={350} textAlign="center" my={2}>Anmeldung</Typography>
			<TextField
				className="inputField"
				variant="outlined"
				placeholder="Benutzername"
				value={username}
				onChange={(e) => setUsername(e.target.value)}
			/>
			<OutlinedInput
				className="inputField"
				id="standard-adornment-password"
				type={showPassword ? "text" : "password"}
				placeholder="Passwort"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
			/>
			<Button
				className="loginButton"
				variant="contained"
				color="primary"
				onClick={authenticate}
				style={{ backgroundColor: "#00451d", color: "#fff" }}
			>
				Anmelden
			</Button>
		</div>
	);
};

export default Login;