class ExportService {

	async downloadExcel(parkingLotNames, fromDate, toDate, frequency) {

		const response = await fetch(process.env.REACT_APP_API_URL + "/reports/excel", {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY
			},
			method: "POST",
			body: JSON.stringify({
				parkingLotNames: parkingLotNames,
				fromDate: fromDate.unix(),
				toDate: toDate.unix(),
				frequency: frequency
			})
		});

		if (response.status === 200) {
			const data = await response.blob();
			return data;
		} else {
			throw new Error("Ein Fehler ist aufgetreten beim erstellen der Excel Datei.");
		}
	}

	async downloadPDF(parkingLotNames, fromDate, toDate, frequency) {

		const response = await fetch(process.env.REACT_APP_API_URL + "/reports/pdf", {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY
			},
			method: "POST",
			body: JSON.stringify({
				parkingLotNames: parkingLotNames,
				fromDate: fromDate.unix(),
				toDate: toDate.unix(),
				frequency: frequency
			})
		});
		if (response.status === 200) {
			const data = await response.blob();
			return data;
		} else {
			throw new Error("Ein Fehler ist aufgetreten beim erstellen der PDF Datei.");
		}
	}

	async downloadForecast(parkingLotNames, fromDate, toDate) {

		const response = await fetch(process.env.REACT_APP_API_URL + "/reports/pdf/forecast", {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY
			},
			method: "POST",
			body: JSON.stringify({
				parkingLotNames: parkingLotNames,
				fromDate: fromDate.unix(),
				toDate: toDate.unix(),
				frequency: 15
			})
		});

		if (response.status === 200) {
			const data = await response.blob();
			return data;
		} else {
			throw new Error("Ein Fehler ist aufgetreten beim erstellen des Forecasts.");
		}
	}
}

export default new ExportService();