import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useProviderMappings } from "../../../hooks/useProviderMappings";
import { ParkingLot } from "../../../types/ParkingLotOwner";
import { ProviderMapping } from "../../../types/ProviderMapping";
import { ParkingLotForm } from "../ParkingLotForm";
import ParkingSpotDetailsInputRow from "../helpers/ParkingSpotDetailsInputRow";

type ParkingSpotTypeOptions = "STANDARD" | "WOMAN" | "DISABLED";
export interface IOccupancyTabProps {
	formValues: ParkingLotForm,
	setFormValues: (value: { parkingLot: ParkingLot }) => void,
	handleInputChange: (e: any) => void,
	formErrors: any,
	setErrors: (e: any) => void
}

const getInitiallySelectedParkingSpotNames = (parkingSpots: any) => {
	const result: Array<string> = [];
	Object.entries(parkingSpots).forEach(([key, value], index) => {
		if (parkingSpots[key].allowOutOfRangeCounting) {
			result.push(key);
		}
	});
	return result;
};

const OccupancyTab = ({ formValues, setFormValues, handleInputChange, formErrors, setErrors }: IOccupancyTabProps) => {

	const { data: providersData, isLoading: isLoadingProviders, isError: providersError } = useProviderMappings();

	const [selectedSpotTypes, setSelectedSpotTypes] = useState<Array<string>>(getInitiallySelectedParkingSpotNames(formValues?.parkingLot?.parkingSpots));
	const [totalMaximumSpots, setTotalMaximumSpots] = useState<number>(0);
	const [totalCurrentlyOccupied, setTotalCurrentlyOccupied] = useState<number>(0);

	const handleParkingSpotUpdate = (spotType: string, field: string, value: number) => {
		if (isNaN(value)) {
			console.error("Please only write numbers into the text field!");
			return;
		}
		const formCopy = { ...formValues };
		if (!formCopy.parkingLot.parkingSpots) formCopy.parkingLot.parkingSpots = {};
		if (!formCopy.parkingLot.parkingSpots[spotType]) formCopy.parkingLot.parkingSpots[spotType] = {};
		formCopy.parkingLot.parkingSpots[spotType][field] = value;

		setFormValues(formCopy);
	};

	const mapParkingSpotTypeToDisplayValue = (value: string): string => {
		switch (value) {
			case "STANDARD": return "Standard";
			case "DISABLED": return "Behindertenparkplatz";
			case "WOMAN": return "Frauenparkplatz";
		}
		return value;
	};

	const handleSelectContinuousCountingChange = (event: SelectChangeEvent<string[]>) => {
		setSelectedSpotTypes(event.target.value as string[]);
		const parkingLotCopy = { ...formValues.parkingLot };
		const parkingSpotsCopy = { ...formValues.parkingLot.parkingSpots };
		Object.entries(parkingSpotsCopy).forEach(([key, value], index) => {
			parkingSpotsCopy[key].allowOutOfRangeCounting = event.target.value.indexOf(key) > -1;
		});
		parkingLotCopy.parkingSpots = parkingSpotsCopy;

		setFormValues({
			...formValues,
			["parkingLot"]: parkingLotCopy
		});
	};

	const onChangeProvider = (providerString: string) => {
		if (providerString === "Keine Anbindung") {
			const formValuesCopy = { ...formValues };
			formValuesCopy.selectedProvider = undefined;
			setFormValues(formValuesCopy);
		} else {
			const [providerName, providerParkingLotName] = providerString.split(" - ");
			const formValuesCopy = { ...formValues };
			formValuesCopy.selectedProvider = { name: providerName, providerParkingLotName: providerParkingLotName };
			setFormValues(formValuesCopy);
		}
	};

	const makeProviderString = (provider: any) => {
		if (provider === undefined || provider === null) {
			return "Keine Anbindung";
		}
		return `${provider.name} - ${provider.providerParkingLotName}`;
	};

	const removeDuplicatesFromProviderMapping = (mappings: ProviderMapping[] | undefined): Array<ProviderMapping> => {
		if (!mappings) {
			return [];
		}

		return mappings.filter((mapping, index) => mappings.findIndex(m => m.provider === mapping.provider && m.providerParkingLotName === mapping.providerParkingLotName) === index);
	};

	return (
		<Stack width="100%">
			<Grid container spacing={2} >
				{/* Title Row */}
				<Grid item xs={5}>
					<Typography variant="body1" sx={{ fontWeight: "bold" }}>Parkplatz-Art</Typography>
				</Grid>
				<Grid item xs={3.5}>
					<Typography variant="body1" sx={{ fontWeight: "bold" }}>Anzahl</Typography>
				</Grid>
				<Grid item xs={3.5}>
					<Typography variant="body1" sx={{ fontWeight: "bold" }}>Besetzt</Typography>
				</Grid>
				{/* Content Rows */}
				<ParkingSpotDetailsInputRow
					spotType="STANDARD"
					currentlyOccupied={formValues.parkingLot.parkingSpots?.STANDARD?.currentlyOccupied}
					maximumSpots={formValues.parkingLot.parkingSpots?.STANDARD?.maximumSpots}
					onChange={handleParkingSpotUpdate}
				/>
				<ParkingSpotDetailsInputRow
					spotType="DISABLED"
					currentlyOccupied={formValues.parkingLot.parkingSpots?.DISABLED?.currentlyOccupied}
					maximumSpots={formValues.parkingLot.parkingSpots?.DISABLED?.maximumSpots}
					onChange={handleParkingSpotUpdate}
				/>
				<ParkingSpotDetailsInputRow
					spotType="WOMAN"
					currentlyOccupied={formValues.parkingLot.parkingSpots?.WOMAN?.currentlyOccupied}
					maximumSpots={formValues.parkingLot.parkingSpots?.WOMAN?.maximumSpots}
					onChange={handleParkingSpotUpdate}
				/>
			</Grid>
			<FormControl margin="normal">
				<InputLabel>Kontinuiertes Zählen eingeschalten für ...</InputLabel>
				<Select
					multiple
					value={selectedSpotTypes}
					onChange={handleSelectContinuousCountingChange}
					data-testid="select-continuous-counting"
					name=""
					input={<OutlinedInput label="Kontinuiertes Zählen eingeschalten für ..." />}
					renderValue={(selected) => selected.map(mapParkingSpotTypeToDisplayValue).join(", ")}
					fullWidth
				>
					{["STANDARD", "DISABLED", "WOMAN"].map((spotType) => (
						<MenuItem
							key={spotType}
							value={spotType}
							data-testid="menuitem"
							sx={{ maxHeight: "40px" }}
						>
							<Checkbox checked={selectedSpotTypes.indexOf(spotType) > -1} />
							<ListItemText primary={mapParkingSpotTypeToDisplayValue(spotType)} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl margin="dense">
				<InputLabel>Anbindung</InputLabel>
				<Select
					labelId="select-provider"
					value={makeProviderString(formValues.selectedProvider)}
					input={<OutlinedInput label="Anbindung" />}
					onChange={(e) => onChangeProvider(e.target.value)}
					fullWidth
				>
					{
						providersError &&
						<Typography>Failed to load data.</Typography>
					}
					{
						!isLoadingProviders && !providersError &&
						<MenuItem value={"Keine Anbindung"}>Keine Anbindung</MenuItem>
					}
					{
						!isLoadingProviders &&
						removeDuplicatesFromProviderMapping(providersData)
							.map((provider) => (
								<MenuItem
									value={`${provider.provider} - ${provider.providerParkingLotName}`}
									sx={{ maxHeight: "40px" }}
									key={`${provider.provider}${provider.providerParkingLotName}`}
								>
									{`${provider.provider} - ${provider.providerParkingLotName}`}
								</MenuItem>
							))
					}
				</Select>
			</FormControl>
			{/* TODO <Typography variant="button" mt={1} mb={0}>14 von 57 Parkplätzen besetzt </Typography> */}
		</Stack>
	);
};

export default OccupancyTab;