import { Button, DialogActions, LinearProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { generalTabComplete, statusTabComplete, validateParkingLotForm } from "./helpers/ValidateParkingLotForm";
import { ParkingLotForm } from "./ParkingLotForm";
import OccupancyTab from "./tabs/OccupancyTab";
import PatchTab from "./tabs/PatchTab";
import StatusTab from "./tabs/StatusTab";
import GeneralTab from "./tabs/GeneralTab";
import PaymentPlanTab from "./tabs/PaymentPlanTab";

export interface IAddParkingLotFormProps {
	values: ParkingLotForm,
	setValues: (x: any) => void,
	handleCheckboxChange: (e: any) => void,
	handleInputChange: (e: any) => void,
	errors: any,
	setErrors: (x: any) => void,
	handleSave: () => void,
	hideDialog: () => void,
	resetFormValues: () => void
}

const AddParkingLotForm = ({ values, setValues, handleCheckboxChange, handleInputChange, errors, setErrors, handleSave, hideDialog, resetFormValues }: IAddParkingLotFormProps) => {

	const [progress, setProgress] = useState(0);

	const handleBackClick = () => {
		if (progress === 0) {
			hideDialog();
			resetFormValues();
		} else {
			setProgress(progress - 1);
		}
	};

	const handleNextClick = () => {
		// validations
		if (progress === 0) {
			const errors = validateParkingLotForm(values, "GENERAL");
			if (!generalTabComplete(errors)) {
				setErrors(errors);
				return;
			}
		}

		if (progress === 2) {
			const errors = validateParkingLotForm(values, "STATUS");
			if (!statusTabComplete(errors)) {
				setErrors(errors);
				return;
			}
		}

		// progress
		if (progress === 4) {
			handleSave();
			hideDialog();
		} else {
			setProgress(progress + 1);
		}
	};

	const renderCurrentTab = () => {
		switch (progress) {
			case 0:
				return <GeneralTab formValues={values} setFormValues={setValues} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
			case 1:
				return <OccupancyTab formValues={values} setFormValues={setValues} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
			case 2:
				return <StatusTab formValues={values} handleInputChange={handleInputChange} setFormValues={setValues} handleCheckboxChange={handleCheckboxChange} formErrors={errors} setErrors={setErrors} />;
			case 3:
				return <PaymentPlanTab formValues={values} setFormValues={setValues} type="ADD" />;
			case 4:
				return <PatchTab formValues={values} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
		}
	};

	return (
		<>
			<Stack width="540px" height="475px">
				<Typography variant="button" mb={1}>Parkplatz Hinzufügen</Typography>
				<LinearProgress variant="determinate" value={progress * 25} style={{ marginBottom: "10px" }} />
				{renderCurrentTab()}
			</Stack>
			<DialogActions>
				<Stack direction="row" justifyContent="space-between" width="540px">
					<Button onClick={handleBackClick}>
						{progress === 0 && "Abbrechen"}
						{progress !== 0 && "Zurück"}
					</Button>
					<Button color="primary" onClick={handleNextClick}>
						{progress !== 4 && "Weiter"}
						{progress === 4 && "Fertigstellen"}
					</Button>
				</Stack>
			</DialogActions>
		</>
	);
};

export default AddParkingLotForm;
