import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import ExportService from "../../service/ExportService";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { useParams } from "react-router";
import { useParkingLotsOfOwner } from "../../hooks/useParkingLotData";
import { ParkingLot } from "../../types/ParkingLotOwner";
import { toast } from "react-toastify";

export type ExportType = "EXCEL" | "FORECAST" | "PDF";

interface Props {
	type: ExportType,
	handleClose: () => void
}

const ExportDialog = ({ type, handleClose }: Props) => {
	dayjs.extend(weekday);

	const { parkingLotOwnerIdentifier } = useParams();
	const { data: parkingLots, isLoading: isLoadingParkingLots } = useParkingLotsOfOwner(parkingLotOwnerIdentifier!);
	const [selectedParkingLotNames, setSelectedParkingLotNames] = useState<Array<string>>([]);
	const [selectedStep, setSelectedStep] = useState(15);
	const [fromValue, setFromValue] = useState(type === "FORECAST" ? dayjs() : dayjs().subtract(7, "days"));
	const [toValue, setToValue] = useState(type === "FORECAST" ? dayjs().add(7, "days") : dayjs());

	const exportSteps = [5, 10, 15, 30, 60, 120, 180];

	const onToggleChange = (event: any, newAlignment: number) => {
		setSelectedStep(newAlignment);
	};

	const handleChangeFromDate = (value: any) => {
		const now = new Date();
		if (type === "FORECAST") {
			if (now <= value && value < toValue) {
				setFromValue(value);
			} else {
				toast("Bitte ein Datum in der Zukunft auswählen!", { type: "error" });
			}
		} else {
			if (value < now && value < toValue) {
				setFromValue(value);
			} else {
				toast("Bitte ein Datum auswählen, das vor dem ausgewählten \"Bis\"-Datum liegt.", { type: "error" });
			}
		}
	};

	const handleChangeToDate = (value: any) => {
		const now = new Date();
		if (type === "FORECAST") {
			if (value > now && value > fromValue) {
				setToValue(value);
			} else {
				toast("Bitte ein Datum auswählen, das vor dem gewählten \"Von\"-Datum liegt.", { type: "error" });
			}
		} else {
			if (value < now && value > fromValue) {
				setToValue(value);
			} else {
				toast("Bitte ein Datum wählen, das in der Vergangenheit & vor dem \"Von\"-Datum liegt.", { type: "error" });
			}
		}
	};

	const transformExportStep = (step: number) => {
		if (step < 60)
			return step + " min";
		if (step === 60)
			return "1 hour";
		return (step / 60) + " hours";
	};

	const handleExport = () => {
		handleClose();
		// display loading
		try {
			if (type === "EXCEL") {
				ExportService.downloadExcel(
					selectedParkingLotNames,
					fromValue,
					toValue,
					selectedStep
				).then(blob => {
					autoDownload(blob, "Parkplatz-Report", "xlsx");
				})
					.catch(e => {
						toast(e.message, { type: "error" });
					})
					.finally(() => {
						// stop display loading
						close();
					});
			} else if (type === "PDF") {
				ExportService.downloadPDF(
					selectedParkingLotNames,
					fromValue,
					toValue,
					selectedStep
				).then(blob => {
					autoDownload(blob, "Parkplatz-Report", "pdf");
				})
					.catch(e => {
						toast(e.message, { type: "error" });
					})
					.finally(() => {
						// stop display loading
						close();
					});
			} else if (type === "FORECAST") {
				ExportService.downloadForecast(
					selectedParkingLotNames,
					fromValue,
					toValue
				).then(blob => {
					autoDownload(blob, "Parkplatz-Vorhersage", "pdf");
				})
					.catch(e => {
						toast(e.message, { type: "error" });
					})
					.finally(() => {
						// stop display loading
						close();
					});
			}
		} catch (e) {
			console.error(e);
		}
	};

	const autoDownload = (blob: any, filename: string, extension: string) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = filename + "." + extension;
		a.click();
	};

	const handleSelectedParkingLotsChange = (e: SelectChangeEvent<string[]>) => {
		if (typeof (e.target.value) === "string") {
			setSelectedParkingLotNames([e.target.value as string]);
		} else {
			setSelectedParkingLotNames(e.target.value as string[]);
		}
	};

	const userFriendlyTypeDescription = (type: ExportType): string => {
		if (type === "EXCEL") {
			return "Excel";
		} else if (type === "FORECAST") {
			return "Forecast";
		} else {
			return type as string;
		}
	};

	return <>
		<DialogTitle id="export-dialog-title">{"Parkplätze Exportieren (" + userFriendlyTypeDescription(type) + ")"}</DialogTitle>
		<DialogContent>
			<Stack
				rowGap={2}
			>
				<Typography>Bitte geben Sie die Details für Ihren Export an.</Typography>

				<FormControl margin="dense">
					<InputLabel>Ausgewählte Parkplätze</InputLabel>
					<Select
						multiple
						value={selectedParkingLotNames}
						onChange={handleSelectedParkingLotsChange}
						input={<OutlinedInput label="Ausgewählte Parkplätze" />}
						renderValue={(selected) => selected.join(", ")}
						fullWidth
					>
						{
							!isLoadingParkingLots &&
							parkingLots!
								.map((parkingLot: ParkingLot) => parkingLot.name)
								.map(
									(parkingLotName: string) => (
										<MenuItem key={parkingLotName} value={parkingLotName} sx={{ maxHeight: "40px" }}>
											<Checkbox checked={selectedParkingLotNames.indexOf(parkingLotName) > -1} />
											<ListItemText primary={parkingLotName} />
										</MenuItem>
									)
								)
						}
					</Select>
				</FormControl>
			</Stack>

			<Stack
				mt={3}
				mb={1}
				rowGap={3}
				alignItems="center"
			>
				<LocalizationProvider dateAdapter={AdapterDayjs as any} >
					<Stack
						direction="row"
						columnGap={3}
					>
						<DesktopDateTimePicker
							label="Von"
							format="DD. MMMM HH:mm"
							value={fromValue}
							ampm={false}
							onChange={handleChangeFromDate}
						/>

						<DesktopDateTimePicker
							label="Bis"
							value={toValue}
							ampm={false}
							format="DD. MMMM HH:mm"
							onChange={handleChangeToDate}
						/>
					</Stack>
				</LocalizationProvider>
				{
					type !== "FORECAST" &&
					<ToggleButtonGroup value={selectedStep} onChange={onToggleChange} exclusive>
						{exportSteps.map(step => {
							return <ToggleButton key={step} size="small" value={step}>
								{transformExportStep(step)}
							</ToggleButton>;
						})}
					</ToggleButtonGroup>
				}
			</Stack>

		</DialogContent >
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Abbrechen
			</Button>
			<div style={{ flex: "1 0 0" }} />
			<Button onClick={handleExport} color="secondary">
				Download
			</Button>
		</DialogActions>
	</>;
};

export default ExportDialog;