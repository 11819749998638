import { MonitorEntry, MonitoringEntryType } from "../types/Monitoring";

class MonitoringService {

	async loadLatestUpdates(): Promise<Array<MonitorEntry>> {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/monitoring/latestUpdates`, {
			headers: {
				API_KEY: process.env.REACT_APP_API_KEY!
			}
		});
		return response.json();
	}

	calculateBackgroundColorForStatusCircle(monitorEntry: MonitorEntry) {
		if (!monitorEntry.latestUpdate || monitorEntry.allowedStaleHours < monitorEntry.staleSinceHours) {
			return "red";
		}

		if ((monitorEntry.allowedStaleHours * 0.75) < monitorEntry.staleSinceHours) {
			return "orange";
		}

		return "green";
	}

	groupMonitoringEntriesByType(array: Array<MonitorEntry>): Array<Array<MonitorEntry>> {
		const result: Array<Array<MonitorEntry>> = [];
		for (const entry of array ?? []) {
			const idx = result.findIndex(sub => sub.some((etr) => etr.type === entry.type));
			if (idx !== -1) {
				result[idx].push(entry);
			} else {
				result.push([entry]);
			}
		}
		return result;
	}

	getDisplayStringForType(type: MonitoringEntryType) {
		switch (type) {
			case "INTERFACE":
				return "Schnittstelle";
			case "PARKING_LOT":
				return "Parkplatz";
			case "SCHEDULED_TASK":
				return "Scheduled Task";
		}
	}

	formatTimestamp(timestamp: string) {
		if (timestamp === undefined || timestamp === null) {
			return "Noch keine Daten erhalten";
		} else {
			const date = new Date(timestamp);
			return date.toLocaleDateString("de-DE", { weekday: "long", month: "long", day: "numeric", year: "numeric" }) + ", " + date.toLocaleTimeString("de-DE");
		}
	}
}

export default new MonitoringService();