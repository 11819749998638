import { Checkbox, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ParkingLotForm } from "../ParkingLotForm";
import LightInputLine from "../helpers/LightInputLine";

export interface IPatchTabProps {
	formValues: ParkingLotForm,
	handleInputChange: (e: any) => void,
	formErrors: any,
	setErrors: (e: any) => void
}

const PatchTab = ({ formValues, handleInputChange }: IPatchTabProps) => {
	const [showPatch, setShowPatch] = useState(formValues.parkingLot?.patch !== null);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setShowPatch(true);
			formValues.parkingLot.patch = { color: "green", text: "", icon: "", parkingLotIdentifier: formValues.parkingLot.identifier };
		} else {
			setShowPatch(false);
			formValues.parkingLot.patch = null;
		}
	};

	if (!formValues.parkingLot) {
		return <></>;
	}

	return (
		<Stack width="100%">
			<LightInputLine formValues={formValues} handleInputChange={handleInputChange} />
			<Stack>
				<Stack direction="row" alignItems={"center"} columnGap={1}>
					<Typography variant="button">Dieser Parkplatz hat einen Sticker</Typography>
					<Checkbox
						name="patch.showing"
						checked={showPatch}
						onChange={handleCheckboxChange}
						color="primary"
					/>
				</Stack>
				{
					showPatch &&
					<TextField
						label="Sticker Text (optimal: ca 30 Zeichen)"
						name="parkingLot.patch.text"
						onChange={handleInputChange}
						value={formValues.parkingLot?.patch?.text ?? ""}
					/>
				}
			</Stack>
		</Stack>
	);
};

export default PatchTab;