import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

interface Props {
	initiallySelected: string,
	disabled: boolean
}

const ParkingCategoryDropdown = ({ initiallySelected, disabled }: Props) => {

	const [selected, setSelected] = useState<any>(initiallySelected ?? "NONE");


	// mayble load this via HTTP request - needed if we have different settings for different communities
	const availableTypes = ["STANDARD", "DISABLED", "WOMAN"];

	const prepareParkingSpotTitles = (title: string) => {
		if (title === "STANDARD") {
			return "Standard";
		}
		if (title === "DISABLED") {
			return "Behindertenparkplatz";
		}
		if (title === "WOMAN") {
			return "Frauenparkplatz";
		}

		const string = title.toLowerCase();
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
		setSelected(event.target.value as string);
	};

	return (
		<Box>
			<Select
				value={selected}
				onChange={handleChange}
				disabled={disabled}
				style={{ width: "210px" }}
			>
				<MenuItem value="NONE">----</MenuItem>
				{
					availableTypes.map(i => {
						return <MenuItem value={i} key={i}>{prepareParkingSpotTitles(i)}</MenuItem>;
					})
				}
			</Select>
		</Box>
	);
};

ParkingCategoryDropdown.propTypes = {
	initiallySelected: PropTypes.string,
	disabled: PropTypes.bool
};

export default ParkingCategoryDropdown;