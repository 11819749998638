import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserDetails } from "../context/UserDetailsContext";
import ParkingLotOwnerService from "../service/ParkingLotOwnerService";
import { ParkingLotOwner } from "../types/ParkingLotOwner";
import { toast } from "react-toastify";

export const useParkingLotOwners = () => {

	const { userDetails } = useUserDetails();

	return useQuery(
		["parkingLotOwner"],
		() => ParkingLotOwnerService.loadAllowedParkingLotOwners(userDetails.user?.allowedAccessCommunityKeys)
	);
};
interface UseParkingLotOwnerDataOpions {
	onSuccess?: (data: ParkingLotOwner) => void
}

export const useParkingLotOwnerData = (parkingLowOwnderIdentifier: string, options: UseParkingLotOwnerDataOpions | undefined = undefined) => {
	return useQuery(
		["parkingLotOwner", parkingLowOwnderIdentifier],
		() => ParkingLotOwnerService.loadOwner(parkingLowOwnderIdentifier),
		{
			onSuccess: options?.onSuccess
		}
	);
};

export const useSaveParkingLotOwner = () => {
	const queryClient = useQueryClient();

	return useMutation(ParkingLotOwnerService.saveParkingLotOwner, {
		onSuccess: (data: ParkingLotOwner) => {
			toast("Gemeindedaten wurden erfolgreich gespeichert!", { type: "success" });
			queryClient.invalidateQueries(["parkingLotOwner", data.name]);
		},
		onError: (data: ParkingLotOwner) => {
			toast("Fehler beim speichern der Gemeindedaten!", { type: "error" });
		}
	});
};