import { useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Switch, IconButton, Dialog, Box, Stack } from "@mui/material";
import GateSwitch from "../../components/UI/GateSwitch";
import TitleLine from "../../components/UI/TitleLine";
import ActionButton from "../../components/UI/ActionButton";
import { Link, useParams } from "react-router-dom";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import LightStatusIndicator from "../../components/UI/LightStatusIndicator";
import IndicatedTooltip from "../../components/UI/IndicatedTooltip";
import { useChangeGateStatus, useDeleteParkingLot, useParkingLotsOfOwner, useSaveParkingLot } from "../../hooks/useParkingLotData";
import { ParkingSpaceStatus } from "../../types/Status";
import { Address, LicencePlateParkingLot, ParkingLot, ParkingSpotTypeDetails } from "../../types/ParkingLotOwner";
import { useDecoupleProviderMapping, useProviderMappings, useSaveProviderMapping } from "../../hooks/useProviderMappings";
import { ProviderMapping } from "../../types/ProviderMapping";
import ParkingLotForm from "../../components/Form/ParkingLotForm";
import DeleteConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import ExportDialog, { ExportType } from "../../components/DialogHelpers/ExportDialog";
import { isLicencePlateParkingLot } from "../../utils/utils";
import ParkingLotService from "../../service/ParkingLotService";

const defaultVisibleDialogs = {
	editParkingLot: undefined
};

interface VisibleDialogs {
	addParkingLot?: boolean,
	editParkingLot?: string,
	export?: ExportType
}

const ParkingLotList = () => {

	const { parkingLotOwnerIdentifier } = useParams();

	const [visibleDialogs, setVisibleDialogs] = useState<VisibleDialogs>(defaultVisibleDialogs);
	const [deleteParkingLotIdentifier, setDeleteParkingLotIdentifier] = useState<string | null>(null);

	const { mutate: saveProviderMapping } = useSaveProviderMapping(parkingLotOwnerIdentifier!);
	const { mutate: handleChangeGateStatus } = useChangeGateStatus(parkingLotOwnerIdentifier!);
	const { mutate: decoupleProviderMapping } = useDecoupleProviderMapping();

	const { data: parkingLots, isLoading: isLoadingParkingLots, error: parkingLotsError } = useParkingLotsOfOwner(parkingLotOwnerIdentifier!);
	const { mutateAsync: saveParkingLot } = useSaveParkingLot(parkingLotOwnerIdentifier!);
	const { mutate: deleteParkingLotByIdentifier } = useDeleteParkingLot(parkingLotOwnerIdentifier!);
	const { data: providerMappings, isLoading: isLoadingProviderMappings, error: providerMappingsError } = useProviderMappings();

	const requestExport = (type: ExportType) => {
		setVisibleDialogs({ export: type });
	};

	const handleDeleteConfirm = () => {
		deleteParkingLotByIdentifier({ parkingLotIdentifier: deleteParkingLotIdentifier! });
		setDeleteParkingLotIdentifier(null);
	};

	const handleSaveParkingLot = async (isAdd: boolean, parkingLot: ParkingLot | LicencePlateParkingLot, providerMapping?: ProviderMapping) => {
		const mutatedParkingLot = await saveParkingLot({
			ownerIdentifier: parkingLotOwnerIdentifier!,
			parkingLot: parkingLot
		});

		if (providerMapping) {
			if (isAdd) { // add parking lot identifier of newly saved lot
				providerMapping.parkingLotIdentifier = mutatedParkingLot.identifier;
			}
			saveProviderMapping(providerMapping);
		} else {
			if (!isAdd) { // decouple mapping if mode is edit
				decoupleProviderMapping(parkingLot.identifier);
			}
		}

	};

	const createStatusSwitch = (parkingLot: ParkingLot, status: ParkingSpaceStatus) => {
		return (
			<Switch
				checked={!status.isSetManually}
				onChange={(e) => {
					const statusCopy = { ...parkingLot.status };
					statusCopy.isSetManually = !e.target.checked;
					saveParkingLot(
						{
							ownerIdentifier: parkingLotOwnerIdentifier!,
							parkingLot: {
								...parkingLot,
								["status"]: statusCopy
							}
						}
					);
				}}
				color="info"
			/>
		);
	};

	const createLotName = (lot: ParkingLot) => {
		if (isLicencePlateParkingLot(lot.type)) {
			return (
				<Link to={`/gemeinden/${parkingLotOwnerIdentifier}/parking-lots/${lot.identifier}`} style={{ color: "black", opacity: 0.87, textDecoration: "none" }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							border: "1px solid rgba(0,0,0,0.5)",
							padding: "2px 3px",
							transition: "ease-in-out .5s",
							borderRadius: "3px",
							"&:hover": { backgroundColor: "#00451d", color: "white" }
						}}>
						{lot.name}
						<Visibility />
					</Stack>
				</Link >
			);
		} else {
			return lot.name;
		}
	};

	const createAddressString = (address: Address) => {
		return address.street + ", " + address.zipCode + " " + address.city;
	};

	const createGateSwitch = (parkingLot: ParkingLot) => {
		if (!parkingLot.gate) {
			return <Box></Box>;
		}
		return <GateSwitch sx={{ m: 1 }}
			onChange={() => handleChangeGateStatus({ parkingLot: parkingLot })}
			checked={parkingLot.gate.gateStatus === "CLOSED"}
		/>;
	};

	const generateAnbindung = (parkingLot: ParkingLot) => {
		if (providerMappingsError || isLoadingProviderMappings) {
			return undefined;
		}
		const provider = providerMappings!.find(mapping => mapping.parkingLotIdentifier === parkingLot.identifier);
		if (!provider) {
			return undefined;
		} else {
			return provider.provider + " - " + provider.providerParkingLotName;
		}
	};

	if (isLoadingParkingLots) {
		return <Box>Loading</Box>;
	}

	if (parkingLotsError) {
		return <Box>Error loading parking lots</Box>;
	}

	return (
		<Box>
			<TitleLine title="Parkplätze">
				<ActionButton title="Hinzufügen" bgColor="#00451d" onClick={() => setVisibleDialogs({ addParkingLot: true })} />
				<ActionButton title="Export Excel" bgColor="#931cc9" onClick={() => requestExport("EXCEL")} />
				<ActionButton title="Export PDF" bgColor="#931cc9" onClick={() => requestExport("PDF")} />
				<ActionButton title="Export Forecast" bgColor="#931cc9" onClick={() => requestExport("FORECAST")} />
			</TitleLine>

			<TableContainer component={Paper}>
				<Table className="table" size="small" aria-label="Parking Lot Table">
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: "bold" }} align="left">Bearbeiten</TableCell>
							<TableCell style={{ fontWeight: "bold" }}>Parkplatz</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">
								<IndicatedTooltip tooltip="Der Status wird auf der Website bei den Parkplätzen angezeigt. Er kann manuell oder automatisch gesetzt werden.">
									<span>Status</span>
								</IndicatedTooltip>
							</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">
								<IndicatedTooltip tooltip="Wenn Automatik eingeschalten ist, dann können verschiedene Anbieter (S&B, Dahua, etc) den Status selbstständig verändern. Wenn automatik ausgeschalten ist, kann der Status nur manuell gesetzt werden.">
									<span>Automatisch</span>
								</IndicatedTooltip>
							</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Adresse</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">
								<IndicatedTooltip tooltip="Die Ampelschaltung kann manuell gesteuert werden. Slider ist grün -> Ampel ist Grün. Slider ist rot -> Ampel ist rot.">
									<span>Ampel</span>
								</IndicatedTooltip>
							</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Parkplätze</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Aktuell Besetzt</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Sticker</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Anbindung</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Infos</TableCell>
							<TableCell style={{ fontWeight: "bold" }} align="right">Löschen</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{parkingLots !== undefined &&
							parkingLots
								.slice()
								.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()))
								.map((lot) => (
									<TableRow key={lot.name}>
										<TableCell align="left">
											<IconButton
												onClick={() => setVisibleDialogs({ editParkingLot: lot.identifier })}
											>
												<Edit />
											</IconButton>
										</TableCell>
										<TableCell component="th" scope="row">
											{createLotName(lot)}
										</TableCell>
										<TableCell align="right">
											<LightStatusIndicator status={lot.status.status}></LightStatusIndicator>
										</TableCell>
										<TableCell align="center">{createStatusSwitch(lot, lot.status)}</TableCell>
										<TableCell align="right">{createAddressString(lot.address)}</TableCell>
										<TableCell align="right">{createGateSwitch(lot)}</TableCell>
										<TableCell align="right">{ParkingLotService.getTotalMaximumSpotsForParkingLot(lot)}</TableCell>
										<TableCell align="right">{ParkingLotService.getTotalOccupiedSpacesForParkingLot(lot)}</TableCell>
										<TableCell align="right">{lot.patch?.text}</TableCell>
										<TableCell align="right">{generateAnbindung(lot)}</TableCell>
										<TableCell align="right">{lot.extras.join()}</TableCell>
										<TableCell align="right">
											<IconButton onClick={() => setDeleteParkingLotIdentifier(lot.identifier)} color="error">
												<Delete />
											</IconButton>
										</TableCell>

									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Add Parking Lot */}
			<Dialog
				open={visibleDialogs.addParkingLot !== undefined}
			>
				<ParkingLotForm
					type="ADD"
					hideDialog={() => setVisibleDialogs({ addParkingLot: undefined })}
					onSave={handleSaveParkingLot}
				/>
			</Dialog>

			{/* Edit Parking Lot */}
			<Dialog
				open={visibleDialogs.editParkingLot !== undefined}
			>
				<ParkingLotForm
					type="EDIT"
					parkingLot={parkingLots!.find(lot => lot.identifier === visibleDialogs.editParkingLot)!}
					hideDialog={() => setVisibleDialogs({ editParkingLot: undefined })}
					onSave={handleSaveParkingLot}
				/>
			</Dialog>

			<DeleteConfirmationDialog
				shouldShow={deleteParkingLotIdentifier !== null}
				title="Parkplatz löschen"
				onCancel={() => setDeleteParkingLotIdentifier(null)}
				onConfirm={handleDeleteConfirm}

			>
				<p style={{ margin: "0 0 15px 0" }}>
					Sind Sie sicher, dass Sie diesen Parkplatz löschen möchten? <br />
					Dieser Vorgang ist nicht wiederherstellbar!
				</p>
			</DeleteConfirmationDialog>

			<Dialog open={visibleDialogs.export === "EXCEL"}>
				<ExportDialog type={"EXCEL"} handleClose={() => setVisibleDialogs({})} />
			</Dialog>

			<Dialog open={visibleDialogs.export === "PDF"}>
				<ExportDialog type={"PDF"} handleClose={() => setVisibleDialogs({})} />
			</Dialog>

			<Dialog open={visibleDialogs.export === "FORECAST"}>
				<ExportDialog type={"FORECAST"} handleClose={() => setVisibleDialogs({})} />
			</Dialog>
		</Box>

	);
};

export default ParkingLotList;