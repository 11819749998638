import { Checkbox, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { ParkingLotForm } from "../ParkingLotForm";

interface Props {
	formValues: ParkingLotForm,
	handleInputChange: (e: ChangeEvent) => void
}

const LightInputLine = ({ formValues, handleInputChange }: Props) => {

	const [showLightLine, setShowLightLine] = useState(formValues.parkingLot.gate !== null);

	const handleVisibilityChange = () => {
		setShowLightLine(!showLightLine);
		if (formValues.parkingLot.gate) {
			formValues.parkingLot.gate = null;
		} else {
			formValues.parkingLot.gate = { telNr: "", password: "", gateStatus: "CLOSED" };
		}
	};

	return (
		<Stack>
			<Stack direction="row" alignItems={"center"} columnGap={1}>
				<Typography variant="button">Dieser Parkplatz hat eine Ampel</Typography>
				<Checkbox
					name="parkingLot.gate"
					checked={showLightLine}
					onChange={handleVisibilityChange}
					color="primary"
				/>
			</Stack>
			<Stack direction="row">
				{
					showLightLine &&
					<>
						<TextField
							id="gateTelNr"
							label="Tel Nr. (Mit +43 beginnen!)"
							style={{ width: "60%" }}
							name="parkingLot.gate.telNr"
							value={formValues.parkingLot.gate?.telNr ?? ""}
							onChange={handleInputChange}
						/>
						<TextField
							id="gatePassword"
							label="Passwort"
							name="parkingLot.gate.password"
							style={{ width: "20%", marginLeft: "15px" }}
							value={formValues.parkingLot.gate?.password ?? ""}
							onChange={handleInputChange}
						/>
					</>
				}
			</Stack>
		</Stack>
	);
};

export default LightInputLine;