import { ParkingLotOwner } from "../types/ParkingLotOwner";
import { ProviderMapping } from "../types/ProviderMapping";

class ProviderMappingService {

	async loadProviderMappings(): Promise<Array<ProviderMapping>> {
		const result = await fetch(process.env.REACT_APP_API_URL + "/providerMapping", {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			}
		});

		return result.json();
	}

	async deleteProviderMapping(mapping: ProviderMapping): Promise<void> {
		await fetch(`${process.env.REACT_APP_API_URL}/providerMapping/${mapping.id}`, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			},
			method: "DELETE"
		});
	}

	async saveProviderMapping(mapping: ProviderMapping): Promise<ProviderMapping> {
		const result = await fetch(process.env.REACT_APP_API_URL + "/providerMapping", {
			headers: {
				"API_KEY": process.env.REACT_APP_API_KEY!,
				"Content-Type": "application/json"
			},
			method: "PUT",
			body: JSON.stringify(mapping)
		});
		return result.json();
	}

	async decoupleProviderMapping(parkingLotIdentifier: string): Promise<void> {
		await fetch(process.env.REACT_APP_API_URL + "/providerMapping/decouple/" + parkingLotIdentifier, {
			headers: {
				"Content-Type": "application/json",
				"API_KEY": process.env.REACT_APP_API_KEY!
			},
			method: "POST"
		});
	}

	getIdentifierForParkingLot(community: ParkingLotOwner, parkingLotName: string): string | undefined {
		return community.parkingLots.find(lot => lot.name === parkingLotName)?.identifier;
	}

	getNameForParkingLotParkingLot(community: ParkingLotOwner, parkingLotIdentifier: string): string | undefined {
		return community.parkingLots.find(lot => lot.identifier === parkingLotIdentifier)?.name;
	}
}

export default new ProviderMappingService();