import { Stack, TextField, Typography } from "@mui/material";

export interface IPaymentPlanDisplayProps {
	name: string,
	allowedParkingMinutes: number,
	priceEuro: number,
	planIdx: number,
	handleUpdate: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, idx: number) => void,
}

const PaymentPlanDisplay = ({ name, allowedParkingMinutes, priceEuro, handleUpdate, planIdx }: IPaymentPlanDisplayProps): JSX.Element => {

	return (
		<Stack p={2} rowGap={2} width="80%" border="3px solid #00451d" borderRadius={2}>
			<Stack justifyContent="space-between" direction="row" columnGap={2}>
				<TextField
					value={name}
					name="name"
					label="Tarifname"
					placeholder="Tarifname"
					sx={{ width: "220px" }}
					onChange={(e) => handleUpdate(e, planIdx)}
				/>
				<Stack direction="row" columnGap={1}>
					<TextField
						type="number"
						value={allowedParkingMinutes}
						name="allowedParkingMinutes"
						onChange={(e) => handleUpdate(e, planIdx)}
						sx={{ display: "inline-block", width: "80px", textAlign: "center", color: "white" }}
					/>
					<Stack justifyContent="center" alignItems={"left"}>
						<Typography> erlaubte</Typography>
						<Typography> Parkminuten</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Stack direction="row" columnGap={2}>
				<TextField
					type="number"
					value={priceEuro}
					name="priceEuro"
					color="primary"
					sx={{ width: "220px" }}
					label="Preis in Euro"
					onChange={(e) => handleUpdate(e, planIdx)}
				/>
				<Typography mt={-1}>entspricht {Math.round((allowedParkingMinutes / 60) * 10) / 10} Stunden</Typography>
			</Stack>
		</Stack >
	);
};

export default PaymentPlanDisplay;