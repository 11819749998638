import { createTheme } from "@mui/material/styles";

export const theme = createTheme(
	{
		palette: {
			primary: {
				main: "#00451d"
			}
		},
		typography: {
			h5: {
				fontSize: "22px"
			},
			h6: {
				fontSize: "22px"
			},
			body1: {
				fontSize: "16px"
			},
			body2: {
				fontSize: "14px"
			}
		},
		components: {
			MuiButton: {
				defaultProps: {
					variant: "outlined",
					style: { backgroundColor: "#426cf5", color: "#fff", border: "0px solid" }
				}
			},
			MuiTextField: {
				defaultProps: {
					variant: "outlined"
				}
			}
		}
	}
);