import { Box, Checkbox, FormControlLabel, IconButton, List, Stack, TextField, Typography } from "@mui/material";
import { ParkingLotForm } from "../ParkingLotForm";
import { LicencePlateParkingLot, ParkingLotPaymentPlan } from "../../../types/ParkingLotOwner";
import PaymentPlanDisplay from "../helpers/PaymentPlanDisplay";
import { AddCircle, Delete } from "@mui/icons-material";
import { useState } from "react";
import { isLicencePlateParkingLot, transformLicencePlateParkingLotToParkingLot, transformParkingLotToLicencePlateParkingLot } from "../../../utils/utils";

export interface IPaymentPlanTabProps {
	formValues: ParkingLotForm,
	setFormValues: (newValues: ParkingLotForm) => void,
	type: "ADD" | "EDIT"
}

const placeholderPaymentPlan = { name: "", allowedParkingMinutes: 0, priceEuro: 0 };

const PaymentPlanTab = ({ formValues, setFormValues, type }: IPaymentPlanTabProps) => {

	const [shouldShowPlans, setShouldShowPlans] = useState(isLicencePlateParkingLot(formValues.parkingLot.type));
	const parkingPlans = (formValues.parkingLot as LicencePlateParkingLot).paymentPlans;

	const handlePaymentPlanEdit = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, planIdx: number) => {
		const parkingLot = (formValues.parkingLot as LicencePlateParkingLot);
		const paymentPlans = parkingLot.paymentPlans;
		const plan = { ...paymentPlans.at(planIdx), [e.target.name]: e.target.value } as ParkingLotPaymentPlan;
		paymentPlans[planIdx] = plan;
		parkingLot.paymentPlans = paymentPlans;

		setFormValues({ ...formValues, parkingLot: parkingLot });
	};

	const handleDelete = (idx: number) => {
		const parkingLot = (formValues.parkingLot as LicencePlateParkingLot);
		parkingLot.paymentPlans.splice(idx, 1);

		setFormValues({ ...formValues, parkingLot: parkingLot });
	};

	const addNewPaymentPlan = () => {
		const parkingLot = (formValues.parkingLot as LicencePlateParkingLot);
		parkingLot.paymentPlans.push(placeholderPaymentPlan);

		setFormValues({ ...formValues, parkingLot: parkingLot });
	};

	const handleCheckedChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
		setShouldShowPlans(checked);
		if (checked) { // transform parking lot to lp parking lot
			const licencePlateParkingLot = transformParkingLotToLicencePlateParkingLot(formValues.parkingLot);
			setFormValues({ ...formValues, parkingLot: licencePlateParkingLot });
		} else { // remove lp parking lot features from lp parking lot
			const parkingLot = transformLicencePlateParkingLotToParkingLot(formValues.parkingLot as LicencePlateParkingLot);
			setFormValues({ ...formValues, parkingLot: parkingLot });
		}
	};

	return (
		<Box>
			{type === "ADD" &&
				<FormControlLabel
					control={
						<Checkbox
							checked={shouldShowPlans}
							onChange={handleCheckedChange}
							color="primary"
						/>
					}
					label={
						isLicencePlateParkingLot(formValues.parkingLot.type) ?
							"Dieser Parkplatz hat über Mobil365 verwaltete Parktarife. Wir erkennen die Kennzeichen und bieten einen Online Checkout an."
							:
							"Dieser Parkplatz hat KEINE über Mobil365 verwalteten Parktarife. Wir tracken lediglich die Auslastung."
					}
					style={{ margin: "5px 0 0 0" }}
				/>
			}
			{
				isLicencePlateParkingLot(formValues.parkingLot.type) &&
				<List style={{ maxHeight: "430px", overflow: "auto" }}>
					<Stack justifyContent="center" alignItems="center">
						<Stack justifyContent="start" alignItems="center" direction="column" rowGap={3}>
							{
								parkingPlans?.map((plan, idx) => (
									<Stack key={idx} justifyContent="start" alignItems="center" direction="row">
										<PaymentPlanDisplay {...plan} planIdx={idx} handleUpdate={handlePaymentPlanEdit} />
										<IconButton
											color="error"
											size="large"
											onClick={() => handleDelete(idx)}
											aria-label="remove-payment-plan-button"
										>
											<Delete fontSize="large" />
										</IconButton>
									</Stack>
								))
							}
						</Stack>
						<IconButton
							color="primary"
							size="large"
							onClick={addNewPaymentPlan}
							aria-label="add-payment-plan-button"
						>
							<AddCircle fontSize="large" />
						</IconButton>
					</Stack>
				</List>
			}
		</Box>
	);
};

export default PaymentPlanTab;