import React from "react";
import { MenuItems } from "./MenuItems";
import "./Navbar.style.css";
import { Link, useNavigate } from "react-router-dom";

const selectedStyle = {
	backgroundColor: "#fff",
	color: "#000",
	borderRadius: "4px"
};

const Navbar = () => {

	useNavigate();

	const createUrl = (url) => {
		const urlSlugs = window.location.pathname.split("/");
		return "/gemeinden/" + urlSlugs[2] + url;
	};

	const isSelected = (itemName) => {
		return window.location.pathname.includes(itemName);
	};

	return (
		<nav className="navbar">
			<Link to="/" className="no-link">
				<img src="/logo-white.svg" alt="Logo" className="logo" />
				<span className="navbar-title">Administrationsportal</span>
			</Link>

			<ul className="nav-menu">
				{MenuItems.map((item) => {
					return <Link key={item.title} className={item.cName} style={isSelected(item.url) ? selectedStyle : {}} to={createUrl(item.url)}><li>{item.title}</li></Link>;
				})}
			</ul>
		</nav>
	);
};

export default Navbar;