import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ParkingLotService from "../service/ParkingLotService";
import { ParkingLotOwner, ParkingLot, LicencePlateParkingLot } from "../types/ParkingLotOwner";
import { toast } from "react-toastify";


export const useParkingLotsOfOwner = (ownerIdentifier: string) => {
	return useQuery(
		["parkingLots", ownerIdentifier],
		() => ParkingLotService.loadParkingLotsForOwner(ownerIdentifier)
	);
};

export const useParkingLotDetails = (parkingLotIdentifier: string) => {
	return useQuery(
		["parkingLotDetails", parkingLotIdentifier],
		() => ParkingLotService.loadParkingLotDetails(parkingLotIdentifier)
	);
};

export const useSaveParkingLot = (ownerIdentifier: string) => {
	const queryClient = useQueryClient();

	return useMutation(ParkingLotService.saveParkingLot, {
		onSuccess: (data: ParkingLot | LicencePlateParkingLot) => {
			toast("Parkplatz wurde erfolgreich gespeichert!", { type: "success" });
			const oldParkingLots = queryClient.getQueryData(["parkingLots", ownerIdentifier]) as ParkingLot[];
			const index = oldParkingLots.findIndex(parkingLot => parkingLot.identifier === data.identifier);
			if (index > -1) {
				// edit parking lot
				oldParkingLots.splice(index, 1, data);
			} else {
				// add new ParkingLot
				oldParkingLots.push(data);
			}
			queryClient.setQueryData(["parkingLots", ownerIdentifier], oldParkingLots);
			queryClient.invalidateQueries(["parkingLotOwner", ownerIdentifier]);

		},
		onError: (data: ParkingLot) => {
			toast("Fehler beim Speichern des Parkplatz!", { type: "error" });
		}
	});
};

export const useChangeGateStatus = (ownerIdentifier: string) => {
	// display loading
	const queryClient = useQueryClient();

	return useMutation(ParkingLotService.handleChangeGateStatus, {
		onSuccess: (data: ParkingLotOwner) => {
			toast("Die Ampel wurde erfolgreich geupdated!", { type: "success" });
			queryClient.setQueryData(["parkingLots", ownerIdentifier], data.parkingLots);
			queryClient.setQueryData(["parkingLotOwner", ownerIdentifier], data);
			// stop display loading
		},
		onError: (data: ParkingLot) => {
			toast("Fehler beim Umschalten der Ampel!", { type: "error" });
			// stop display loading
		}
	});
};

export const useDeleteParkingLot = (ownerIdentifier: string) => {
	const queryClient = useQueryClient();

	return useMutation(ParkingLotService.deleteParkingLot, {
		onSuccess: () => {
			toast("Parkplatz wurde erfolgreich gelöscht!", { type: "success" });
			queryClient.invalidateQueries(["parkingLots", ownerIdentifier]);
			queryClient.invalidateQueries(["parkingLotOwner", ownerIdentifier]);
		},
		onError: (data: ParkingLot) => {
			toast("Fehler beim Löschen des Parkplatz!", { type: "error" });
		}
	});
};