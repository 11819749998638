import React, { useContext, useEffect, useState } from "react";
import UserService from "../service/UserService";
import { User } from "../types/User";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { SettingsInputSvideoRounded } from "@mui/icons-material";

export interface UserDetails {
	loginState: "LOADING" | "LOGGED_IN" | "UNAUTHORIZED"
	user?: User
}

interface UserDetailsContext {
	userDetails: UserDetails
	setUserDetails: (newUserDetails: UserDetails) => void
}

const defaultValue: UserDetails = { loginState: "UNAUTHORIZED" };

export const UserDetailsContext = React.createContext<UserDetailsContext | null>(null);

interface Props {
	children: JSX.Element
}

export default function UserContextProvider({ children }: Props) {

	const queryClient = useQueryClient();
	const [userDetails, setUserDetails] = useState(defaultValue);
	const navigate = useNavigate();

	useEffect(() => {
		const userCookie = Cookies.get("usercookie");
		const userApiKey = Cookies.get("user-apikey");
		if (userCookie && userApiKey) {
			UserService.checkCookieLogin(userCookie, userApiKey, (user: User) => {
				setUserDetails({ loginState: "LOGGED_IN", user: user });
				navigate("/");
			});
		}
	}, []);

	const updateUserDetails = (userDetails: UserDetails) => {
		setUserDetails(userDetails);
		queryClient.invalidateQueries(["community"]);
	};

	return (
		<UserDetailsContext.Provider value={{ userDetails, setUserDetails: updateUserDetails }}>
			{children}
		</UserDetailsContext.Provider>
	);
}

export function useUserDetails() {
	return useContext(UserDetailsContext) as UserDetailsContext;
}