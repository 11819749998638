
import { Box } from "@mui/material";
import { LightStatus } from "../../types/Status";
import React from "react";

interface Props {
	status: LightStatus
	sx?: object
}

const LightStatusIndicator = ({ status, sx }: Props) => {

	const getColorForStatus = () => {
		switch (status) {
			case "GREEN":
				return "green";
			case "ORANGE":
				return "orange";
			case "RED":
				return "red";
			case "OFFLINE":
				return "gray";
			case "NOT_IN_SYSTEM":
				return "gray";
		}
	};

	return (
		<Box sx={{ height: "20px", width: "20px", backgroundColor: getColorForStatus(), borderRadius: "50%", display: "inline-block", ...sx }} />
	);
};

export default LightStatusIndicator;