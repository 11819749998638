import { Box, Checkbox, Grid, TextField } from "@mui/material";
import ParkingCategoryDropdown from "../../DialogHelpers/ParkingCategoryDropdown";

interface Props {
	spotType: string,
	maximumSpots: number
	currentlyOccupied: number
	onChange: (spotType: string, field: string, value: number) => void
}

const ParkingSpotDetailsInputRow = ({ spotType, currentlyOccupied, maximumSpots, onChange }: Props) => {

	return (
		<>
			<Grid item xs={5}>
				<ParkingCategoryDropdown
					disabled
					initiallySelected={spotType}
				/>
			</Grid>
			<Grid item xs={3.5}>
				<TextField
					name="maximumSpots"
					sx={{ justifySelf: "start" }}
					value={maximumSpots}
					inputProps={{ "data-testid": `${spotType}-spot-max-value` }}
					onChange={(e) => onChange(spotType, e.target.name, Number(e.target.value))}
				/>
			</Grid>
			<Grid item xs={3.5}>
				<TextField
					name="currentlyOccupied"
					sx={{ justifySelf: "start" }}
					value={currentlyOccupied}
					inputProps={{ "data-testid": `${spotType}-spot-current-value` }}
					onChange={(e) => onChange(spotType, e.target.name, Number(e.target.value))}
				/>
			</Grid>
		</>
	);
};

export default ParkingSpotDetailsInputRow;