import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProviderMappingService from "../service/ProviderMappingService";
import { ProviderMapping } from "../types/ProviderMapping";

interface UseProviderMappingsOptions {
	onSuccess?: (data: Array<ProviderMapping>) => void
}

export const useProviderMappings = (options: UseProviderMappingsOptions | undefined = undefined) => {
	return useQuery(
		["providerMappings"],
		ProviderMappingService.loadProviderMappings,
		{
			onSuccess: (data) => {
				if (options?.onSuccess) {
					options.onSuccess(data);
				}
			}
		}
	);
};

export const useDeleteProviderMapping = () => {
	const queryClient = useQueryClient();

	return useMutation(ProviderMappingService.deleteProviderMapping, {
		onSuccess: () => {
			queryClient.invalidateQueries(["providerMappings"]);
		},
	});
};

export const useSaveProviderMapping = (parkingLowOwnerIdentifier: string) => {
	const queryClient = useQueryClient();

	return useMutation(ProviderMappingService.saveProviderMapping, {
		onSuccess: () => {
			queryClient.invalidateQueries(["providerMappings"]);
			queryClient.invalidateQueries(["parkingLotOwner", parkingLowOwnerIdentifier]);
		},
	});
};

export const useDecoupleProviderMapping = () => {
	const queryClient = useQueryClient();

	return useMutation(ProviderMappingService.decoupleProviderMapping, {
		onSuccess: () => {
			queryClient.invalidateQueries(["providerMappings"]);
		},
	});
};