import { Dialog, DialogContent, DialogTitle, Stack, Typography, Divider, TextField, Box, List, ListItem, DialogActions, Button, FormControlLabel, Checkbox } from "@mui/material";
import { validateParkingLotForm } from "../helpers/ValidateParkingLotForm";
import LightStatusSelector from "../../UI/LightStatusSelector";
import { ChangeEvent } from "react";
import { ParkingLotForm } from "../ParkingLotForm";
import { ParkingLot } from "../../../types/ParkingLotOwner";

export interface IStatusTabProps {
	formValues: ParkingLotForm,
	setFormValues: (values: ParkingLot) => void,
	handleInputChange: (e: any) => void,
	handleCheckboxChange: (e: any) => void,
	formErrors: any,
	setErrors: (e: any) => void
}

const StatusTab = ({ formValues, setFormValues, handleInputChange, handleCheckboxChange, formErrors, setErrors }: IStatusTabProps) => {

	const handleBlur = (e: any) => {
		const err = validateParkingLotForm(formValues, e.target.name);
		setErrors(err);
	};

	return (
		<Stack direction="column" spacing={2}>
			<Stack>
				<Typography variant="overline">Manuell</Typography>
				<FormControlLabel
					control={
						<Checkbox
							name="parkingLot.status.isSetManually"
							checked={formValues.parkingLot.status.isSetManually}
							onChange={handleCheckboxChange}
							color="primary"
						/>
					}
					label={
						formValues.parkingLot.status.isSetManually ?
							"Der Status ist auf 'manuell' gesetzt."
							:
							"Der Status ist auf 'automatisch' gesetzt."
					}
					style={{ margin: "5px 0 0 0" }}
				/>
				<LightStatusSelector
					initialLightStatus={formValues.parkingLot.status.status}
					onChange={(e: any) => { handleInputChange(e); handleCheckboxChange({ target: { name: "parkingLot.status.isSetManually", checked: "true" } }); }}
				/>
			</Stack>
			<Divider />
			<Stack spacing={1}>
				<Box>
					<Typography variant="overline">Automatisch</Typography>
					<Typography variant="subtitle2">Automatische Einstellungen sind dann aktiv, wenn der Status nicht manuell gesetzt wurde.</Typography>
				</Box>
				<List sx={{ listStyleType: "disc", pl: 2 }}>
					<ListItem sx={{ display: "list-item" }}>
						<Typography variant="body1" component="div" display="inline-block">
							Status wird Orange, wenn Auslastung größer gleich
							<TextField
								name="parkingLot.status.orangeLowerPercentage"
								data-testid="tf-orange-lower"
								type="number"
								value={formValues.parkingLot.status.orangeLowerPercentage}
								inputProps={{ style: { textAlign: "center" } }}
								sx={{ display: "inline-block", width: "40px", marginTop: "-4px", textAlign: "center" }}
								variant="standard"
								error={formErrors?.parkingLot?.status?.orangeLowerPercentage !== undefined}
								helperText={formErrors?.parkingLot?.status?.orangeLowerPercentage}
								onBlur={handleBlur}
								onChange={handleInputChange}
							/>
							%
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item" }}>
						<Typography variant="body1" component="div" display="inline-block">
							Status wird Rot, wenn Auslastung größer gleich
							<TextField
								name="parkingLot.status.orangeUpperPercentage"
								data-testid="tf-orange-upper"
								type="number"
								value={formValues.parkingLot.status.orangeUpperPercentage}
								inputProps={{ style: { textAlign: "center" } }}
								sx={{ display: "inline-block", width: "40px", marginTop: "-4px", textAlign: "center" }}
								variant="standard"
								onBlur={handleBlur}
								error={formErrors?.parkingLot?.status?.orangeUpperPercentage !== undefined}
								helperText={formErrors?.parkingLot?.status?.orangeUpperPercentage}
								onChange={handleInputChange}
							/>
							%
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item" }}>
						<Typography variant="body1" component="div" display="inline-block">
							Wenn
							<TextField
								name="parkingLot.status.allowedStaleHours"
								data-testid="tf-allowed-stale-hours"
								value={formValues.parkingLot.status.allowedStaleHours}
								inputProps={{ style: { textAlign: "center" } }}
								sx={{ display: "inline-block", width: "40px", marginTop: "-4px", textAlign: "center" }}
								variant="standard"
								type="number"
								error={formErrors?.parkingLot?.status?.allowedStaleHours !== undefined}
								helperText={formErrors?.parkingLot?.status?.allowedStaleHours}
								onChange={handleInputChange}
								onBlur={handleBlur}
							/>
							Stunden keine Daten an diesen Parkplatz kommen, wird eine Warnungs Email versendet.
						</Typography>
					</ListItem>
				</List>
			</Stack>
		</Stack>
	);
};

export default StatusTab;