import { Switch, styled } from "@mui/material";

export default styled(Switch)(() => ({
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			"& + .MuiSwitch-track": {
				backgroundColor: "#ff3300",
			},
			"& .MuiSwitch-thumb": {
				backgroundColor: "red",
			} 
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: "#00b300",
		borderRadius: 20 / 2,
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: "green",
	} 
}));