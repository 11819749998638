import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const ActionButton = ({onClick, bgColor, title}) =>(
	<Button
		variant="outlined"
		style={{ backgroundColor: bgColor ?? "#426cf5", color: "#fff", border: "0px solid", marginTop: "10px"}}
		onClick={onClick}
	>
		{title}
	</Button>
);

ActionButton.propTypes = {
	onClick: PropTypes.func,
	bgColor: PropTypes.string,
	title: PropTypes.string
};


export default ActionButton;