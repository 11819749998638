export const MenuItems = [

	{
		title: "Stammdaten",
		url: "/basic-data",
		cName: "nav-links"
	},
	{
		title: "Parkplätze",
		url: "/parking-lots",
		cName: "nav-links"
	},
	{
		title: "Monitoring",
		url: "/monitoring",
		cName: "nav-links"
	},
	{
		title: "Anbindungen",
		url: "/anbindung",
		cName: "nav-links"
	}
];