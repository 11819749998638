import { Box, Button, DialogActions, DialogContent, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { Patch } from "../../types/Patch";
import { ParkingLotForm } from "./ParkingLotForm";
import OccupancyTab from "./tabs/OccupancyTab";
import PatchTab from "./tabs/PatchTab";
import StatusTab from "./tabs/StatusTab";
import GeneralTab from "./tabs/GeneralTab";
import PaymentPlanTab from "./tabs/PaymentPlanTab";

interface PatchForm {
	showing: boolean,
	details: Patch
}
export interface IEditParkingLotFormProps {
	values: ParkingLotForm,
	setValues: (x: any) => void,
	handleCheckboxChange: (e: any) => void,
	handleInputChange: (e: any) => void,
	errors: any,
	setErrors: (x: any) => void,
	handleSave: () => void,
	hideDialog: () => void,
	isLicencePlateParkingLot: boolean
}

const tabNames = ["Allgemein", "Details", "Status", "Tarife", "Anderes"] as const;
type TabName = typeof tabNames[number];


const EditParkingLotForm = ({ values, setValues, handleCheckboxChange, handleInputChange, errors, setErrors, handleSave, hideDialog, isLicencePlateParkingLot }: IEditParkingLotFormProps) => {

	const [currentTab, setCurrentTab] = useState<TabName>("Allgemein");

	const prepareTabNames = (): Array<TabName> => {
		if (isLicencePlateParkingLot) {
			return [tabNames[0], tabNames[1], tabNames[2], tabNames[3], tabNames[4]];
		} else {
			return [tabNames[0], tabNames[1], tabNames[2], tabNames[4]];
		}
	};

	const renderSelectedTab = () => {
		switch (currentTab) {
			case "Allgemein":
				return <GeneralTab formValues={values} setFormValues={setValues} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
			case "Details":
				return <OccupancyTab formValues={values} setFormValues={setValues} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
			case "Status":
				return <StatusTab formValues={values} handleInputChange={handleInputChange} setFormValues={setValues} handleCheckboxChange={handleCheckboxChange} formErrors={errors} setErrors={setErrors} />;
			case "Tarife":
				return <PaymentPlanTab formValues={values} setFormValues={setValues} type="EDIT" />;
			case "Anderes":
				return <PatchTab formValues={values} handleInputChange={handleInputChange} formErrors={errors} setErrors={setErrors} />;
		}
	};

	return (
		<>
			<DialogContent sx={{ minHeight: "480px" }}>
				<Box sx={{ width: "540px" }}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						rowGap={2}
					>
						<ToggleButtonGroup value={currentTab} onChange={(_, n) => { if (n !== null) setCurrentTab(n); }} exclusive>
							{prepareTabNames().map(name => (
								<ToggleButton key={name} sx={{ paddingX: 2 }} size="small" value={name}>{name}</ToggleButton>
							))}
						</ToggleButtonGroup>
						{renderSelectedTab()}
					</Stack>
				</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction="row" justifyContent="space-between" width="540px">
					<Button onClick={hideDialog}>
						Abbrechen
					</Button>
					<Button color="primary" onClick={handleSave}>
						Speichern
					</Button>
				</Stack>
			</DialogActions>
		</>
	);
};

export default EditParkingLotForm;