import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useParkingLotDetails } from "../../hooks/useParkingLotData";
import { isLicencePlateParkingLot } from "../../utils/utils";
import CarDataGrid from "./CarDataGrid";
import { LicencePlateParkingLot } from "../../types/ParkingLotOwner";
import ParkingLotService from "../../service/ParkingLotService";
import { ArrowBack } from "@mui/icons-material";
import ActionButton from "../../components/UI/ActionButton";
import { useState } from "react";
import ExportParkingTicketDialog from "./ExportParkingTicketDialog";

const ParkingLotDetails = () => {

	const { parkingLotIdentifier } = useParams();
	const navigate = useNavigate();
	const [showExportExcelDialog, setShowExportDialog] = useState(false);

	const { data: parkingLot, isLoading, isError } = useParkingLotDetails(parkingLotIdentifier!);

	if (isLoading) {
		return (
			<Stack
				width="100%"
				alignItems="center"
				justifyContent="center"
				height="200px"
			>
				<CircularProgress />
			</Stack>
		);
	}

	if (isError) {
		return (<Box>Error occurred during loading</Box>);
	}

	return (
		<Box>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				p={2}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<IconButton sx={{ height: 25 }} onClick={() => navigate(-1)}>
						<ArrowBack fontSize="medium" />
					</IconButton>
					<Typography variant="h5">{parkingLot.name}</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" columnGap={2}>
					<Typography variant="body1">
						{ParkingLotService.getTotalOccupiedSpacesForParkingLot(parkingLot)} von {ParkingLotService.getTotalMaximumSpotsForParkingLot(parkingLot)} Parkplätzen ({Math.round((ParkingLotService.getTotalOccupiedSpacesForParkingLot(parkingLot) / ParkingLotService.getTotalMaximumSpotsForParkingLot(parkingLot)) * 100) / 100}%) besetzt.
					</Typography>
					<ActionButton title="Export" bgColor="#931cc9" onClick={() => setShowExportDialog(true)} />
				</Stack>
			</Stack>

			{isLicencePlateParkingLot(parkingLot.type) &&
				<CarDataGrid cars={(parkingLot as LicencePlateParkingLot).cars} />
			}

			<ExportParkingTicketDialog
				shouldShow={showExportExcelDialog}
				handleClose={() => setShowExportDialog(false)}
				parkingLotIdentifier={parkingLot.identifier}
				parkingLotName={parkingLot.name}
			/>
		</Box>
	);
};

export default ParkingLotDetails;