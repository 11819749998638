import { LicencePlateParkingLot, ParkingLot } from "../../types/ParkingLotOwner";
import { ProviderMapping } from "../../types/ProviderMapping";
import { Box, DialogContent, Stack } from "@mui/material";
import EditParkingLotForm from "./EditParkingLotForm";
import AddParkingLotForm from "./AddParkingLotForm";
import { useParams } from "react-router";
import { useForm } from "../../hooks/useForm";
import { useProviderMappings } from "../../hooks/useProviderMappings";
import clone from "../../utils/Clone";
import { isLicencePlateParkingLot } from "../../utils/utils";

export interface ParkingLotForm {
	parkingLot: ParkingLot | LicencePlateParkingLot,
	selectedProvider?: { name: string, providerParkingLotName: string }
}

const placeholderParkingLot: unknown = {
	tags: [],
	identifier: null,
	status: {
		status: "GREEN",
		orangeLowerPercentage: 80,
		orangeUpperPercentage: 90,
		isSetManually: false,
		allowedStaleHours: 12
	},
	extras: [],
	name: "",
	address: {
		street: "",
		zipCode: undefined,
		city: ""
	},
	gate: null,
	parkingSpots: {
		STANDARD: {
			maximumSpots: 50,
			currentlyOccupied: 25
		},
		WOMAN: {
			maximumSpots: 0,
			currentlyOccupied: 0
		},
		DISABLED: {
			maximumSpots: 0,
			currentlyOccupied: 0
		}
	},
	patch: null,
	link: { qrCodeDestinationURL: "" }
};

export interface IParkingLotFormProps {
	type: "EDIT" | "ADD"
	parkingLot?: ParkingLot,
	hideDialog: () => void,
	onSave: (isAdd: boolean, parkingLot: ParkingLot, providerMapping?: ProviderMapping) => void,
}

const findSelectedProviderForParkingLot = (providerMappings: Array<ProviderMapping> | undefined, parkingLot: ParkingLot | undefined): { name: string, providerParkingLotName: string } | undefined => {
	const mapping = providerMappings?.find(mapping => mapping?.parkingLotIdentifier === parkingLot?.identifier);
	if (mapping === undefined) {
		return undefined;
	}
	return {
		name: mapping.provider,
		providerParkingLotName: mapping.providerParkingLotName
	};
};

const ParkingLotForm = ({ type, parkingLot, hideDialog, onSave }: IParkingLotFormProps) => {

	const { data: providerMappings } = useProviderMappings({
		onSuccess: (data) => {
			formData.setValues({ ...formData.values, selectedProvider: findSelectedProviderForParkingLot(data, parkingLot) });
		}
	});

	// get all values for form
	const formData = useForm<ParkingLotForm>({
		parkingLot: parkingLot !== undefined ? clone(parkingLot) : placeholderParkingLot,
		selectedProvider: findSelectedProviderForParkingLot(providerMappings, parkingLot)

	});

	const handleSave = async () => {
		hideDialog();
		const parkingLot = formData.values.parkingLot;

		if (parkingLot.link?.qrCodeDestinationURL === "") {
			parkingLot.link = null;
		}

		if (parkingLot.extras && typeof parkingLot.extras === "string") {
			// make the extras string an array
			parkingLot.extras = formData.values.parkingLot.extras?.split(",");
		}
		if (parkingLot.extras === "" || parkingLot.extras === undefined || parkingLot.extras === null) {
			parkingLot.extras = [];
		}
		// get full provider mapping
		let providerMapping = undefined;
		if (formData.values.selectedProvider) {
			// find existing mapping
			providerMapping = providerMappings?.find(mapping => mapping.parkingLotIdentifier === parkingLot.identifier);
			if (providerMapping === undefined) {
				// make new mapping ( no existing mapping found )
				providerMapping = {
					provider: formData.values.selectedProvider.name,
					providerParkingLotName: formData.values.selectedProvider.providerParkingLotName,
					parkingLotIdentifier: parkingLot.identifier
				};
			} else {
				// edit old mapping ( change providerName & providerParkingLot of existing mapping )
				providerMapping.provider = formData.values.selectedProvider.name;
				providerMapping.providerParkingLotName = formData.values.selectedProvider.providerParkingLotName;
			}
		}
		onSave(type === "ADD", parkingLot, providerMapping);
		// reset form
		if (type === "ADD") {
			formData.setValues(placeholderParkingLot);
		}
	};

	return (
		<DialogContent sx={{ minHeight: "480px" }}>
			<Box sx={{ width: "540px" }}>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					rowGap={2}
				>
					{type === "EDIT" &&
						<EditParkingLotForm
							{...formData}
							hideDialog={hideDialog}
							handleSave={handleSave}
							isLicencePlateParkingLot={isLicencePlateParkingLot(parkingLot?.type)}
						/>
					}
					{
						type === "ADD" &&
						<AddParkingLotForm
							{...formData}
							hideDialog={hideDialog}
							handleSave={handleSave}
							resetFormValues={() => formData.setValues(placeholderParkingLot)}
						/>
					}
				</Stack>
			</Box>
		</DialogContent>
	);
};

export default ParkingLotForm;
