import { User } from "../types/User";
import { UserDetails } from "../context/UserDetailsContext";

class UserService {

	async checkCookieLogin(userCookie: string, apiKey: string, onSuccess: (userDetails: User) => void) {
		const response = await fetch(process.env.REACT_APP_API_URL + "/admin-cookie-login", {
			headers: {
				API_KEY: apiKey
			},
			method: "POST",
			body: userCookie
		});
		if (response.status === 200) {
			const data = await response.json();
			onSuccess(data);
		}
	}

	async usernamePasswordLogin(username: string, password: string, callbacks: { onError: (msg: string) => void, onSuccess: (userDetails: UserDetails) => void }) {
		try {

			const response = await fetch(process.env.REACT_APP_API_URL + "/admin-login", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ username, password })
			});
			if (response.status === 200) {
				const data = await response.json();
				callbacks.onSuccess({
					loginState: "LOGGED_IN",
					user: data
				});
			} else {
				if (response.status === 500) {
					callbacks.onError("Unbekannte Username + Passwort Kombination.");
				} else {
					callbacks.onError("Der Login ist fehlgeschlagen. Bitte versuchen sie es später erneut!");
				}
			}
		} catch (e) {
			callbacks.onError("Der Login ist fehlgeschlagen. Bitte versuchen sie es später erneut!");
		}
	}
}

export default new UserService();