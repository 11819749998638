import { MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { LightStatus } from "../../types/Status";
import LightStatusIndicator from "./LightStatusIndicator";

interface Props {
	initialLightStatus: any,
	onChange: (e: any) => void
}

const LightStatusSelector = ({ initialLightStatus, onChange }: Props) => {


	const makeMenuItemContent = (color: LightStatus, text: string) => {
		return (
			<Stack
				direction="row"
				columnGap={2}
				alignContent="center"
			>
				<LightStatusIndicator status={color} sx={{ marginTop: "2px" }} />
				<Typography variant="button">{text}</Typography>
			</Stack>
		);
	};

	return (<Select
		value={initialLightStatus}
		margin="dense"
		name="parkingLot.status.status"
		onChange={onChange}
	>
		<MenuItem value={"GREEN"}>
			{makeMenuItemContent("GREEN", "Frei")}
		</MenuItem>
		<MenuItem value={"ORANGE"}>
			{makeMenuItemContent("ORANGE", "Ausgelastet")}
		</MenuItem>
		<MenuItem value={"RED"}>
			{makeMenuItemContent("RED", "Vollständig Besetzt")}
		</MenuItem>
		<MenuItem value={"OFFLINE"}>
			{makeMenuItemContent("OFFLINE", "Offline")}
		</MenuItem>
		<MenuItem value={"NOT_IN_SYSTEM"}>
			{makeMenuItemContent("NOT_IN_SYSTEM", "Nicht im System")}
		</MenuItem>
	</Select>
	);
};

export default LightStatusSelector;