import { ParkingLotForm } from "../ParkingLotForm";

export const validateParkingLotForm = (formValues: ParkingLotForm, fieldToValidate: string | undefined) => {
	const errors: any = {};

	// GENERAL
	if (fieldToValidate === "parkingLot.name" || fieldToValidate === undefined || fieldToValidate === "GENERAL") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }

		if ((formValues.parkingLot.name === "" || !formValues.parkingLot.name)) {
			errors.parkingLot.name = "Der Parkplatzname darf nicht leer sein!";
		} else {
			errors.parkingLot.name = undefined;
		}
	}


	if (fieldToValidate === "parkingLot.address.street" || fieldToValidate === undefined || fieldToValidate === "GENERAL") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.address) { errors.parkingLot.address = {}; }

		if (formValues.parkingLot.address.street === "" || !formValues.parkingLot.address.street) {
			errors.parkingLot.address.street = "Die Straße darf nicht leer sein!";
		} else {
			errors.parkingLot.address.street = undefined;
		}
	}

	if (fieldToValidate === "parkingLot.address.zipCode" || fieldToValidate === undefined || fieldToValidate === "GENERAL") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.address) { errors.parkingLot.address = {}; }

		if (formValues.parkingLot.address.zipCode === undefined || formValues.parkingLot.address.zipCode?.toString().length < 4) {
			errors.parkingLot.address.zipCode = "Die PLZ muss mindestens 4 stellig sein";
		} else {
			errors.parkingLot.address.zipCode = undefined;
		}

	}


	if (fieldToValidate === "parkingLot.address.city" || fieldToValidate === undefined || fieldToValidate === "GENERAL") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.address) { errors.parkingLot.address = {}; }

		if (formValues.parkingLot.address.city === "" || !formValues.parkingLot.address.city) {
			errors.parkingLot.address.city = "Die Stadt darf nicht leer sein!";
		} else {
			errors.parkingLot.address.city = undefined;
		}
	}



	// STATUS
	if (fieldToValidate === "parkingLot.status.orangeLowerPercentage" || fieldToValidate === undefined || fieldToValidate === "STATUS") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.status) { errors.parkingLot.status = {}; }

		if (formValues.parkingLot.status.orangeLowerPercentage as any === "" || !formValues.parkingLot.status.orangeLowerPercentage) {
			errors.parkingLot.status.orangeLowerPercentage = "Füge eine Zahl ein!";
		} else {
			errors.parkingLot.status.orangeLowerPercentage = undefined;
		}
	}

	if (fieldToValidate === "parkingLot.status.orangeUpperPercentage" || fieldToValidate === undefined || fieldToValidate === "STATUS") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.status) { errors.parkingLot.status = {}; }

		if (formValues.parkingLot.status.orangeUpperPercentage as any === "" || !formValues.parkingLot.status.orangeUpperPercentage) {
			errors.parkingLot.status.orangeUpperPercentage = "Füge eine Zahl ein!";
		} else {
			errors.parkingLot.status.orangeUpperPercentage = undefined;
		}
	}

	if (fieldToValidate === "parkingLot.status.allowedStaleHours" || fieldToValidate === undefined || fieldToValidate === "STATUS") {
		if (!errors.parkingLot) { errors.parkingLot = {}; }
		if (!errors.parkingLot.status) { errors.parkingLot.status = {}; }
		if (formValues.parkingLot.status.allowedStaleHours as any === "" || !formValues.parkingLot.status.allowedStaleHours) {
			errors.parkingLot.status.allowedStaleHours = "Füge eine Zahl ein!";
		} else {
			errors.parkingLot.status.allowedStaleHours = undefined;
		}
	}

	return errors;
};

export const generalTabComplete = (errors: any) => {
	if (errors.parkingLot?.name !== undefined ||
		errors.parkingLot?.address?.city !== undefined ||
		errors.parkingLot?.address?.zipCode !== undefined ||
		errors.parkingLot?.address?.street !== undefined) {
		return false;
	} else {
		return true;
	}
};

export const statusTabComplete = (errors: any) => {
	if (
		errors.parkingLot?.status?.orangeLowerPercentage !== undefined ||
		errors.parkingLot?.status?.orangeUpperPercentage !== undefined ||
		errors.parkingLot?.status?.allowedStaleHours !== undefined
	) {
		return false;
	} else {
		return true;
	}
};