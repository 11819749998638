import { useState } from "react";
import { setUsingKey } from "../utils/utils";

export function useForm<T>(initialFormValues: T) {

	const [values, setValues] = useState<any>(initialFormValues);
	const [errors, setErrors] = useState<any>({});

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;

		if (name.includes(".")) {
			const valuesCopy = { ...values };
			setValues(setUsingKey(valuesCopy, name, value));
			const errorsCopy = { ...errors };
			setErrors(setUsingKey(errorsCopy, name, undefined));
		} else {
			setValues({
				...values,
				[name]: value
			});
			setErrors({
				...errors,
				[name]: null
			});
		}
	};

	const handleCheckboxChange = (e: any) => {
		const { name, checked } = e.target;

		if (name.includes(".")) {
			const valuesCopy = { ...values };
			setValues(setUsingKey(valuesCopy, name, checked));
		} else {
			setValues({
				...values,
				[name]: checked
			});
		}
	};

	return {
		values,
		setValues,
		handleCheckboxChange,
		handleInputChange,
		errors,
		setErrors
	};
}
